import React, { Component } from 'react';
import styled from '@emotion/styled/macro';
import { H2, Colors } from '@mous221/hk-utils';
import PropTypes from 'prop-types';
import {
  Medical2X,
  Medical,
  XRay,
  XRay2X,
  Ecg,
  Ecg2X,
  Algro,
  Algro2X,
  Lab,
  Lab2X,
  BloodPressure2X,
  BloodPressure,
  BloodSugar2X,
  BloodSugar,
  HeartRate,
  HeartRate2X,
  Height,
  Height2X,
  Temperature,
  Temperature2X,
  Weight,
  Weight2X,
  Condition,
  Condition2X,
  CTScan,
  CTScan2X,
  MRI,
  MRI2X,
} from 'images';

const ActivityCartWrapper = styled.div`
  display: flex;
  border-radius: 7.2px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px ${Colors.gary};
  padding: 16px;
  background: ${Colors.white};
  margin-bottom: 20px;
`;
const ActivityCartImgWrapper = styled.div`
  margin-right: 16px;
`;
const ActivityCartImg = styled.picture`
  width: 60px;
  height: 60px;
  img {
    width: 60px;
    height: 60px;
  }
`;
const ActivityCartContent = styled.div`
  h2 {
    margin-bottom: 5px;
    line-height: 1;
  }
  p {
    margin-top: 0;
    font-size: 18px;
    font-weight: 500;
    color: ${Colors.primaryColor};
    margin-bottom: 0;
    &:first-of-type {
      margin-bottom: 16px;
    }
  }
`;

export default class Action extends Component {
  render() {
    const { className, title, time, content, type } = this.props;
    const handleImg = () => {
      switch (type) {
        case 'consultation_request':
        case 'consultation_accepted':
        case 'scan_request':
          return (
            <>
              <source srcSet={Condition2X} />
              <img src={Condition} alt={title} />
            </>
          );
        case 'medical_report':
          return (
            <>
              <source srcSet={Medical2X} />
              <img src={Medical} alt={title} />
            </>
          );
        case 'radiology-ct':
          return (
            <>
              <source srcSet={CTScan2X} />
              <img src={CTScan} alt={title} />
            </>
          );
        case 'radiology-xray':
          return (
            <>
              <source srcSet={XRay2X} />
              <img src={XRay} alt={title} />
            </>
          );
        case 'radiology-mri':
          return (
            <>
              <source srcSet={MRI2X} />
              <img src={MRI} alt={title} />
            </>
          );
        case 'ecg':
          return (
            <>
              <source srcSet={Ecg2X} />
              <img src={Ecg} alt={title} />
            </>
          );
        case 'allergy':
          return (
            <>
              <source srcSet={Algro2X} />
              <img src={Algro} alt={title} />
            </>
          );
        case 'condition':
          return (
            <>
              <source srcSet={Condition2X} />
              <img src={Condition} alt={title} />
            </>
          );
        case 'lab_result':
          return (
            <>
              <source srcSet={Lab2X} />
              <img src={Lab} alt={title} />
            </>
          );
        case 'reading-blood-pressure':
          return (
            <>
              <source srcSet={BloodPressure2X} />
              <img src={BloodPressure} alt={title} />
            </>
          );
        case 'reading-blood-sugar':
          return (
            <>
              <source srcSet={BloodSugar2X} />
              <img src={BloodSugar} alt={title} />
            </>
          );
        case 'reading-weight':
          return (
            <>
              <source srcSet={Weight2X} />
              <img src={Weight} alt={title} />
            </>
          );
        case 'reading-height':
          return (
            <>
              <source srcSet={Height2X} />
              <img src={Height} alt={title} />
            </>
          );
        case 'reading-heart-rate':
          return (
            <>
              <source srcSet={HeartRate2X} />
              <img src={HeartRate} alt={title} />
            </>
          );
        case 'reading-temperature':
          return (
            <>
              <source srcSet={Temperature2X} />
              <img src={Temperature} alt={title} />
            </>
          );
        default:
          return (
            <>
              <source srcSet={Condition2X} />
              <img src={Condition} alt={title} />
            </>
          );
      }
    };
    return (
      <ActivityCartWrapper className={className && className}>
        <ActivityCartImgWrapper>
          <ActivityCartImg>{handleImg()}</ActivityCartImg>
        </ActivityCartImgWrapper>
        <ActivityCartContent>
          <H2>{title}</H2>
          <p>{content}</p>
          <p>{time}</p>
        </ActivityCartContent>
      </ActivityCartWrapper>
    );
  }
}

Action.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  img: PropTypes.object.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
};
