import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Lab, PlusIcon } from 'images';
import {
  Breadcrumb,
  H1,
  Pagination,
  StateCard,
  Modal,
} from '@mous221/hk-utils';
import { patientReportsActions, consultationActions } from 'redux/actions';
import { Container, RequestMedicalRecordForm } from '@mous221/hk-components';
import styled from '@emotion/styled/macro';
import { device } from 'utils';

const MedicalReportsWrapper = styled.div`
  h1 {
    font-size: 30px;
  }
  a {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #9298ac;
  }
  .ant-breadcrumb-separator {
    margin-right: 5px;
    color: #9298ac;
  }
  .add {
    margin-bottom: 25px;
  }
  .pagination {
    max-width: 780px;
    margin-left: 0;
  }
  .request {
    display: block;
    margin: 15px 0;
    color: #97d7d8;
    border: none;
    background: none;
    cursor: pointer;
    img {
      margin-right: 10px;
      width: 25px;
      height: 25px;
      margin-top: 20px;
      position: relative;
      top: -11px;
    }
  }
  .modal__content {
    @media ${device.allMobile} {
      width: 80%;
    }
  }
`;

class MedicalReports extends Component {
  componentDidMount() {
    const {
      match: {
        params: { patientId },
      },
      getSharedMedicalReports,
    } = this.props;

    if (patientId) {
      getSharedMedicalReports(patientId);
    }
  }
  state = {
    showModal: false,
  };

  handelCloseModal = () => {
    this.setState({ showModal: false });
  };

  handelShowModal = () => {
    this.setState({ showModal: true });
  };

  setPage = (page) => {
    const {
      match: {
        params: { patientId },
      },
      getSharedMedicalReports,
    } = this.props;

    if (patientId) {
      getSharedMedicalReports(patientId, null, page);
    }
  };

  render() {
    const {
      reports: medicalReports,
      match: {
        params: { consultationId, patientId },
      },
      requestScan,
      location,
    } = this.props;
    const { showModal } = this.state;

    const BreadcrumbLinks = consultationId
      ? [
          {
            item: 'Medical Record ',
            url: `/consultation/${consultationId}`,
          },
        ]
      : '';
    const canRequest = new URLSearchParams(location.search).get('canRequest');
    return (
      <Container>
        <MedicalReportsWrapper>
          <Breadcrumb links={BreadcrumbLinks} />
          <H1>Medical Reports</H1>
          {canRequest && (
            <button
              className="request"
              to="#!"
              onClick={() => this.handelShowModal()}
            >
              <img src={PlusIcon} alt="Plus" />
              Request Medical Reports
            </button>
          )}
          {medicalReports &&
            medicalReports.data.map((report) => (
              <StateCard
                to={`/consultation/${consultationId}/medical-report/${report.id}?patientId=${patientId}`}
                key={report.createdAt}
                title={report.title}
                time={report.createdAt}
              />
            ))}
          {medicalReports &&
            medicalReports.meta.total > medicalReports.meta.per_page && (
              <Pagination
                pageSize={medicalReports.meta.per_page}
                total={medicalReports.meta.total}
                setPage={this.setPage}
              />
            )}
          <Modal
            title={'Request Medical Reports'}
            image={Lab}
            showModal={showModal}
            handelCloseModal={this.handelCloseModal}
          >
            <>
              <RequestMedicalRecordForm
                requestScan={requestScan}
                scanType={'medical_report'}
                consultationId={Number(consultationId)}
                handelCloseModal={this.handelCloseModal}
              />
            </>
          </Modal>
        </MedicalReportsWrapper>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { reports } = state.patientReports;
  return {
    reports,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSharedMedicalReports: (patientId, sharedToken, page = null) =>
    dispatch(
      patientReportsActions.getSharedMedicalReports(
        patientId,
        sharedToken,
        page
      )
    ),
  requestScan: ({ scanType, id, title, body }) =>
    dispatch(consultationActions.requestScan({ scanType, id, title, body })),
});

MedicalReports.propTypes = {
  reports: PropTypes.object,
  match: PropTypes.object.isRequired,
  getSharedMedicalReports: PropTypes.func.isRequired,
  requestScan: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MedicalReports);
