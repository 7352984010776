import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { consultantActions } from 'redux/actions';
import {
  // ActivityCart,
  H1,
  // convertDateToFullStringDate,
  // Pagination,
} from '@mous221/hk-utils';
// import { Condition, Condition2X } from 'images';
import { MainLayout, Container } from '@mous221/hk-components';
import Action from './Action';

const ActivityWrapper = styled.div`
  margin-top: 30px;
  padding: 30px;
  background: #fff;
  border-radius: 18px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  .title-wrapper {
    width: 100;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .activity-wrapper {
    a + a {
      margin-top: 15px;
      display: block;
    }
  }
`;

class MyActivity extends Component {
  componentDidMount() {
    const { getMyActivity } = this.props;
    getMyActivity();
  }

  // setPage = (page) => {
  //   const { getMyActivity } = this.props;
  //   getMyActivity(page);
  // };

  render() {
    const { activity, logout } = this.props;
    return (
      <MainLayout footer="main" logout={logout}>
        <Container>
          <ActivityWrapper>
            <div className="title-wrapper">
              <H1>My Activity</H1>
            </div>
            <div className="activity-wrapper">
              {activity &&
                activity.data.map((action) => (
                  <Action
                    text={action.activity_text}
                    key={action.created_at}
                    time={action.created_at}
                  />
                ))}
            </div>
            {/* {activity && activity.meta.total > activity.meta.per_page && (
              <Pagination
                pageSize={activity.meta.per_page}
                total={activity.meta.total}
                setPage={this.setPage}
              />
            )} */}
          </ActivityWrapper>
        </Container>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { activity } = state.consultantAuth;

  return {
    activity,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMyActivity: () => dispatch(consultantActions.getMyActivity()),
  logout: () => dispatch(consultantActions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyActivity);

MyActivity.propTypes = {
  getMyActivity: PropTypes.func.isRequired,
  activity: PropTypes.object,
  logout: PropTypes.func.isRequired,
};
