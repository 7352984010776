const getSchedules = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`/consultant/schedules`, requestOptions)
    .then((schedules) => {
      return { error: false, schedules: schedules };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getMyBookedAppointments = (month) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(
    `/consultant/appointments${month ? `?monthStartTime=${month}` : ''}`,
    requestOptions
  )
    .then((appointments) => {
      return { error: false, appointments: appointments };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const addSchedules = (schedules) => {
  const raw = JSON.stringify({ schedules: schedules });
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: raw,
  };
  return fetch(`/consultant/schedules`, requestOptions)
    .then((schedule) => {
      return { error: false, schedules: schedule };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const updateSchedules = (schedule) => {
  const raw = JSON.stringify(schedule);
  const requestOptions = {
    method: 'PATCH',
    redirect: 'follow',
    body: raw,
  };
  return fetch(`/consultant/schedules`, requestOptions)
    .then((schedule) => {
      return { error: false, schedule: schedule };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const schedulesServies = {
  getMyBookedAppointments,
  getSchedules,
  addSchedules,
  updateSchedules,
};

export default schedulesServies;
