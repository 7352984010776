import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { patientReportsActions } from 'redux/actions';
import { Container, MedicalReport } from '@mous221/hk-components';
class Condition extends Component {
  componentDidMount() {
    const {
      getCondition,

      match: {
        params: { id, consultationId },
      },
    } = this.props;

    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );

    if (consultationId && id && patientId) {
      getCondition(id, patientId);
    }
  }
  render() {
    const {
      report: condition,
      match: {
        params: { consultationId },
      },
    } = this.props;
    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );

    const BreadcrumbLinks =
      consultationId && patientId
        ? [
            {
              item: 'Medical Record ',
              url: `/consultation/${consultationId}`,
            },
            {
              item: 'Conditions',
              url: `/consultation/${consultationId}/conditions/${patientId}`,
            },
          ]
        : '';
    return (
      <Container>
        {condition && (
          <MedicalReport
            title={condition.value}
            updatedAt={condition.updatedAt}
            description={condition.description}
            BreadcrumbLinks={BreadcrumbLinks}
            consultationId={consultationId}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { report } = state.patientReports;
  return {
    report,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getCondition: (id, patientId) =>
    dispatch(patientReportsActions.getCondition(id, patientId)),
});

Condition.propTypes = {
  getCondition: PropTypes.func.isRequired,
  report: PropTypes.object,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Condition);
