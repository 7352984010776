import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { CheckIcon, AlertIcon } from 'images';
import { ProfilePic } from '@mous221/hk-utils';

const NotVerified = styled.p`
  color: #f0ba11;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 3px;
  }
  span {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
`;
const Verified = styled.p`
  color: #1abe65;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 3px;
  }
  span {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
`;
const UserProfileCardWrapper = styled.div`
  padding: 24px;
  text-align: center;
  h3 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #060c2b;
  }
  button {
    background: #fff;
    border-radius: 6px;
    border: solid 0.5px #97d7d8;
    width: 100%;
    padding: 10px;
    color: #97d7d8;
    font-family: Heebo;
    font-size: 15px;
    font-weight: 500;
    line-height: 30px;
    vertical-align: middle;
    .anticon {
      margin-right: 10px;
      font-size: 15px;
      line-height: 30px;

      vertical-align: bottom;
    }
  }
`;

const UserProfileCard = (props) => {
  const { name, verifiedAt } = props.user;
  return (
    <UserProfileCardWrapper
      style={{
        backgroundColor: '#fff',
        boxShadow: '',
        textAlign: 'center',
        padding: 24,
      }}
    >
      <ProfilePic
        user={props.user}
        style={{ borderRadius: '50%' }}
        width="100"
        height="100"
      />
      <h3>{name}</h3>
      {verifiedAt ? (
        <Verified>
          <CheckIcon color={'#1abe65'} width={16} height={16} />
          <span>Verified</span>
        </Verified>
      ) : (
        <NotVerified>
          <AlertIcon color={'#f0ba11'} width={16} height={16} />
          <span>Need to Verifiy</span>
        </NotVerified>
      )}
    </UserProfileCardWrapper>
  );
};

export default UserProfileCard;

UserProfileCard.propTypes = {
  user: PropTypes.object.isRequired,
};
