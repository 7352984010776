import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Lab, PlusIcon } from 'images';
import { patientReportsActions, consultationActions } from 'redux/actions';
import {
  Breadcrumb,
  H1,
  Pagination,
  StateCard,
  Modal,
} from '@mous221/hk-utils';
import { Container, RequestMedicalRecordForm } from '@mous221/hk-components';
import styled from '@emotion/styled/macro';
import { device } from 'utils';

const ConditionsWrapper = styled.div`
  h1 {
    font-size: 30px;
  }
  a {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #9298ac;
  }
  .ant-breadcrumb-separator {
    margin-right: 5px;
    color: #9298ac;
  }
  .add {
    margin-bottom: 25px;
  }
  .pagination {
    max-width: 780px;
    margin-left: 0;
  }
  .request {
    display: block;
    margin: 15px 0;
    color: #97d7d8;
    border: none;
    background: none;
    cursor: pointer;
    img {
      margin-right: 10px;
      width: 25px;
      height: 25px;
      margin-top: 20px;
      position: relative;
      top: -11px;
    }
  }
  .modal__content {
    @media ${device.allMobile} {
      width: 80%;
    }
  }
`;

class Conditions extends Component {
  componentDidMount() {
    const {
      getSharedConditions,
      match: {
        params: { patientId },
      },
    } = this.props;

    if (patientId) {
      getSharedConditions(patientId);
    }
  }
  state = {
    showModal: false,
  };

  handelCloseModal = () => {
    this.setState({ showModal: false });
  };

  handelShowModal = () => {
    this.setState({ showModal: true });
  };

  setPage = (page) => {
    const {
      match: {
        params: { patientId },
      },
      getSharedConditions,
    } = this.props;
    if (patientId) {
      getSharedConditions(patientId, null, page);
    }
  };

  render() {
    const {
      reports: conditions,
      match: {
        params: { consultationId, patientId },
      },
      requestScan,
      location,
    } = this.props;
    const { showModal } = this.state;
    const BreadcrumbLinks = consultationId
      ? [
          {
            item: 'Medical Record ',
            url: `/consultation/${consultationId}`,
          },
        ]
      : '';
    const canRequest = new URLSearchParams(location.search).get('canRequest');
    return (
      <Container>
        <ConditionsWrapper>
          <Breadcrumb links={BreadcrumbLinks} />
          <H1>Pre-existing Conditions</H1>
          {canRequest && (
            <button className="request" onClick={() => this.handelShowModal()}>
              <img src={PlusIcon} alt="Plus" />
              Request Pre-existing Conditions
            </button>
          )}

          {conditions &&
            conditions.data.map((condition) => (
              <StateCard
                to={`/consultation/${consultationId}/condition/${condition.id}?patientId=${patientId}`}
                key={condition.createdAt}
                title={condition.value}
                time={condition.createdAt}
              />
            ))}

          {conditions && conditions.meta.total > conditions.meta.per_page && (
            <Pagination
              pageSize={conditions.meta.per_page}
              total={conditions.meta.total}
              setPage={this.setPage}
            />
          )}
          <Modal
            title={'Request Pre-existing conditions'}
            image={Lab}
            showModal={showModal}
            handelCloseModal={this.handelCloseModal}
          >
            <>
              <RequestMedicalRecordForm
                requestScan={requestScan}
                scanType={'condition'}
                consultationId={Number(consultationId)}
                handelCloseModal={this.handelCloseModal}
              />
            </>
          </Modal>
        </ConditionsWrapper>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  const { reports } = state.patientReports;
  return {
    reports,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSharedConditions: (id, sharedToken = null, page = null) =>
    dispatch(patientReportsActions.getSharedConditions(id, sharedToken, page)),
  requestScan: ({ scanType, id, title, body }) =>
    dispatch(consultationActions.requestScan({ scanType, id, title, body })),
});

Conditions.propTypes = {
  reports: PropTypes.object,
  match: PropTypes.object.isRequired,
  getSharedConditions: PropTypes.func.isRequired,
  requestScan: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Conditions);
