import { patientReportsConstants } from 'redux/actions/types';

const initialState = {
  report: null,
  reports: null,
  radiologist: null,
};

export function patientReports(state = initialState, action) {
  switch (action.type) {
    case patientReportsConstants.GET_XRAY_REQUEST:
      return {
        ...state,
        fetchingReport: true,
      };
    case patientReportsConstants.GET_XRAY_SUCCESS:
      return {
        fetchingReport: false,
        report: action.xray,
      };
    case patientReportsConstants.GET_XRAY_FAILURE:
      return {
        fetchingReport: false,
        error: action.error,
      };
    case patientReportsConstants.GET_SHARED_XRAYS_REQUEST:
      return {
        ...state,
        fetchingReports: true,
      };
    case patientReportsConstants.GET_SHARED_XRAYS_SUCCESS:
      return {
        fetchingReports: false,
        reports: action.xrays,
      };
    case patientReportsConstants.GET_SHARED_XRAYS_FAILURE:
      return {
        fetchingReports: false,
        error: action.error,
      };

    case patientReportsConstants.GET_MRI_REQUEST:
      return {
        ...state,
        fetchingReport: true,
      };
    case patientReportsConstants.GET_MRI_SUCCESS:
      return {
        fetchingReport: false,
        report: action.mri,
      };
    case patientReportsConstants.GET_MRI_FAILURE:
      return {
        fetchingReport: false,
        error: action.error,
      };
    case patientReportsConstants.GET_SHARED_MRIS_REQUEST:
      return {
        ...state,
        fetchingReports: true,
      };
    case patientReportsConstants.GET_SHARED_MRIS_SUCCESS:
      return {
        fetchingReports: false,
        reports: action.mris,
      };
    case patientReportsConstants.GET_SHARED_MRIS_FAILURE:
      return {
        fetchingReports: false,
        error: action.error,
      };

    case patientReportsConstants.GET_CT_REQUEST:
      return {
        ...state,
        fetchingReport: true,
      };
    case patientReportsConstants.GET_CT_SUCCESS:
      return {
        fetchingReport: false,
        report: action.ct,
      };
    case patientReportsConstants.GET_CT_FAILURE:
      return {
        fetchingReport: false,
        error: action.error,
      };
    case patientReportsConstants.GET_SHARED_CTS_REQUEST:
      return {
        ...state,
        fetchingReports: true,
      };
    case patientReportsConstants.GET_SHARED_CTS_SUCCESS:
      return {
        fetchingReports: false,
        reports: action.cts,
      };
    case patientReportsConstants.GET_SHARED_CTS_FAILURE:
      return {
        fetchingReports: false,
        error: action.error,
      };

    case patientReportsConstants.GET_ALLERGY_REQUEST:
      return {
        ...state,
        fetchingReport: true,
      };
    case patientReportsConstants.GET_ALLERGY_SUCCESS:
      return {
        fetchingReport: false,
        report: action.allergy,
      };
    case patientReportsConstants.GET_ALLERGY_FAILURE:
      return {
        fetchingReport: false,
        error: action.error,
      };
    case patientReportsConstants.GET_SHARED_ALLERGIES_REQUEST:
      return {
        ...state,
        fetchingReports: true,
      };
    case patientReportsConstants.GET_SHARED_ALLERGIES_SUCCESS:
      return {
        fetchingReports: false,
        reports: action.allergies,
      };
    case patientReportsConstants.GET_SHARED_ALLERGIES_FAILURE:
      return {
        fetchingReports: false,
        error: action.error,
      };

    case patientReportsConstants.GET_CONDITION_REQUEST:
      return {
        ...state,
        fetchingReport: true,
      };
    case patientReportsConstants.GET_CONDITION_SUCCESS:
      return {
        fetchingReport: false,
        report: action.condition,
      };
    case patientReportsConstants.GET_CONDITION_FAILURE:
      return {
        fetchingReport: false,
        error: action.error,
      };
    case patientReportsConstants.GET_SHARED_CONDITIONS_REQUEST:
      return {
        ...state,
        fetchingReports: true,
      };
    case patientReportsConstants.GET_SHARED_CONDITIONS_SUCCESS:
      return {
        fetchingReports: false,
        reports: action.conditions,
      };
    case patientReportsConstants.GET_SHARED_CONDITIONS_FAILURE:
      return {
        fetchingReports: false,
        error: action.error,
      };

    case patientReportsConstants.GET_ECG_REQUEST:
      return {
        ...state,
        fetchingReport: true,
      };
    case patientReportsConstants.GET_ECG_SUCCESS:
      return {
        fetchingReport: false,
        report: action.ecg,
      };
    case patientReportsConstants.GET_ECG_FAILURE:
      return {
        fetchingReport: false,
        error: action.error,
      };
    case patientReportsConstants.GET_SHARED_ECGS_REQUEST:
      return {
        ...state,
        fetchingReports: true,
      };
    case patientReportsConstants.GET_SHARED_ECGS_SUCCESS:
      return {
        fetchingReports: false,
        reports: action.ecgs,
      };
    case patientReportsConstants.GET_SHARED_ECGS_FAILURE:
      return {
        fetchingReports: false,
        error: action.error,
      };

    case patientReportsConstants.GET_LAB_RESULT_REQUEST:
      return {
        ...state,
        fetchingReport: true,
      };
    case patientReportsConstants.GET_LAB_RESULT_SUCCESS:
      return {
        fetchingReport: false,
        report: action.labResult,
      };
    case patientReportsConstants.GET_LAB_RESULT_FAILURE:
      return {
        fetchingReport: false,
        error: action.error,
      };
    case patientReportsConstants.GET_SHARED_LAB_RESULTS_REQUEST:
      return {
        ...state,
        fetchingReports: true,
      };
    case patientReportsConstants.GET_SHARED_LAB_RESULTS_SUCCESS:
      return {
        fetchingReports: false,
        reports: action.labResults,
      };
    case patientReportsConstants.GET_SHARED_LAB_RESULTS_FAILURE:
      return {
        fetchingReports: false,
        error: action.error,
      };

    case patientReportsConstants.GET_REPORT_REQUEST:
      return {
        ...state,
        fetchingReport: true,
      };
    case patientReportsConstants.GET_REPORT_SUCCESS:
      return {
        fetchingReport: false,
        report: action.medicalReport,
      };
    case patientReportsConstants.GET_REPORT_FAILURE:
      return {
        fetchingReport: false,
        error: action.error,
      };
    case patientReportsConstants.GET_SHARED_REPORTS_REQUEST:
      return {
        ...state,
        fetchingReports: true,
      };
    case patientReportsConstants.GET_SHARED_REPORTS_SUCCESS:
      return {
        fetchingReports: false,
        reports: action.medicalReports,
      };
    case patientReportsConstants.GET_SHARED_REPORTS_FAILURE:
      return {
        fetchingReports: false,
        error: action.error,
      };

    case patientReportsConstants.GET_RADIOLOGY_REQUEST:
      return {
        ...state,
        fetchingReport: true,
      };
    case patientReportsConstants.GET_RADIOLOGY_SUCCESS:
      return {
        fetchingReport: false,
        report: action.radiology,
      };
    case patientReportsConstants.GET_RADIOLOGY_FAILURE:
      return {
        fetchingReport: false,
        error: action.error,
      };
    case patientReportsConstants.GET_SHARED_RADIOLOGIST_REQUEST:
      return {
        ...state,
        fetchingReports: true,
      };
    case patientReportsConstants.GET_SHARED_RADIOLOGIST_SUCCESS:
      return {
        fetchingReports: false,
        reports: action.radiologist,
        radiologist: action.radiologist,
      };
    case patientReportsConstants.GET_SHARED_RADIOLOGIST_FAILURE:
      return {
        fetchingReports: false,
        error: action.error,
      };
    case patientReportsConstants.CLEAR_DATA:
      return { loading: false, report: null, reports: null };
    default:
      return state;
  }
}
