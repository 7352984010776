const getNewConsultation = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`/consultant/consultation-request`, requestOptions)
    .then((consultations) => {
      return { error: false, consultations: consultations };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getPatientActivity = (id) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`/patient/${id}/notifications`, requestOptions)
    .then((activity) => {
      return { error: false, activity };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getAcceptedConsultation = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`/consultant/consultation-request/my-requests`, requestOptions)
    .then((consultations) => {
      return { error: false, consultations: consultations };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const rejectConsultation = (id) => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
  };
  return fetch(`/consultant/consultation-request/${id}/reject`, requestOptions)
    .then((consultation) => {
      return { error: false, consultation: consultation };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const acceptConsultation = (id) => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
  };
  return fetch(`/consultant/consultation-request/${id}/accept`, requestOptions)
    .then((consultation) => {
      return { error: false, consultation: consultation };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getConsultation = (id) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`/consultant/consultation-request/single/${id}`, requestOptions)
    .then((consultation) => {
      return { error: false, consultation: consultation };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const requestScan = ({ scanType, id, title, body }) => {
  const formdata = new FormData();
  formdata.append('consultationId', id);
  formdata.append('scan_type', scanType);
  formdata.append('title', title);
  formdata.append('body', body);

  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: formdata,
  };
  return fetch(`/consultant/consultant-actions/request-scan`, requestOptions)
    .then((scan) => {
      return { error: false, scan: scan };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const sendReport = ({ title, note, consultationId }) => {
  const formdata = new FormData();
  formdata.append('consultationId', consultationId);
  formdata.append('note', note);
  formdata.append('title', title);

  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: formdata,
  };
  return fetch(`/consultant/medical-reports`, requestOptions)
    .then((report) => {
      return { error: false, report: report };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getFinishedConsultations = (page, name, date) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  let prams = null;
  if (name && !date && !page) {
    prams = `?patientName=${name}`;
  } else if (!name && date && !page) {
    prams = `?reportDate=${date}`;
  } else if (!name && !date && page) {
    prams = `?page=${page}`;
  } else if (name && date && page) {
    prams = `?page=${page}&reportDate=${date}&patientName=${name}`;
  } else if (name && date && !page) {
    prams = `?reportDate=${date}&patientName=${name}`;
  } else if (!name && date && page) {
    prams = `?reportDate=${date}&page=${page}`;
  } else if (name && !date && page) {
    prams = `?patientName=${name}&page=${page}`;
  }
  return fetch(
    `/consultant/consultation-request/done${prams ? prams : ''}`,
    requestOptions
  )
    .then((FinishedConsultations) => {
      return { error: false, FinishedConsultations };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const requestQuestion = ({ title, body }, consultationId) => {
  const question = JSON.stringify({ title, body });

  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: question,
  };
  return fetch(
    `/consultant/consultant-actions/${consultationId}/request-question`,
    requestOptions
  )
    .then((question) => {
      return { error: false, question: question };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};
const getQuestions = (consultationId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(
    `/consultant/consultant-actions/${consultationId}/question`,
    requestOptions
  )
    .then((questions) => {
      return { error: false, questions: questions };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const consultationServices = {
  getNewConsultation,
  getConsultation,
  getAcceptedConsultation,
  rejectConsultation,
  acceptConsultation,
  requestScan,
  sendReport,
  getFinishedConsultations,
  getPatientActivity,
  requestQuestion,
  getQuestions,
};
export default consultationServices;
