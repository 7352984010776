import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  BaseLayout,
  LoginForm,
  FullPage,
  Container,
} from '@mous221/hk-components';
import { consultantActions, alertActions } from 'redux/actions';
import { history } from 'utils';

class Login extends Component {
  componentWillMount() {
    localStorage.setItem('userType', 'consultant');
    const user = JSON.parse(localStorage.getItem('user'));

    if (user) {
      history.push('/');
    }
  }
  render() {
    return (
      <BaseLayout>
        <FullPage>
          <Container>
            <LoginForm
              {...this.props}
              registerLink="/register"
              loading={this.props.loading}
            />
          </Container>
        </FullPage>
      </BaseLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { loggingIn, loading } = state.consultantAuth;
  return {
    loggingIn,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  verifyConsultant: ({ userId, token }) =>
    dispatch(consultantActions.verify({ userId, token })),
  errorAlert: (err) => dispatch(alertActions.error(err)),
  login: (email, password) =>
    dispatch(consultantActions.login(email, password)),
  resetPassword: (email) => dispatch(consultantActions.resetPassword(email)),
});

const connectedLoginPage = connect(mapStateToProps, mapDispatchToProps)(Login);

Login.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default connectedLoginPage;
