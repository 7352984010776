import React from 'react';
import { connect } from 'react-redux';
import { consultantActions } from 'redux/actions';
import { MainLayout, Container } from '@mous221/hk-components';
import { Reports } from './components';

const ReportsPage = (props) => (
  <MainLayout
    footer="main"
    children={
      <Container>
        <Reports {...props} />
      </Container>
    }
    ReportPage={true}
    {...props}
  />
);
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(consultantActions.logout()),
});

export default connect(() => ({}), mapDispatchToProps)(ReportsPage);
