import React, { Component } from 'react';
import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { patientReportsActions } from 'redux/actions';
import { device } from 'utils';
import {
  Medical2X,
  Medical,
  Redilogy2X,
  Redilogy,
  XRay,
  XRay2X,
  Ecg,
  Ecg2X,
  Algro,
  Algro2X,
  Lab,
  Lab2X,
} from 'images';
import { convertDateToFullStringDate } from '@mous221/hk-utils';
import { isMobile } from 'react-device-detect';
const MedicalRecordWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  .medical-record__title {
    font-size: 20px;
    font-weight: bold;
    color: #060c2b;
  }
  .ant-row {
    .ant-col {
      &:first-of-type > div {
        margin-right: auto;
      }
      &:nth-of-type(3n + 2) > div {
        margin: auto;
        @media ${device.allMobile} {
          margin: 0;
        }
      }
      &:nth-of-type(3n + 3) > div {
        margin-left: auto;
        @media ${device.allMobile} {
          margin: 0;
        }
      }
      @media ${device.allMobile} {
        &:nth-of-type(2n + 2) > div {
          margin-left: auto;
          margin-bottom: 15px;
        }
      }
    }
  }
`;

const Record = styled.div`
  width: 95%;
  background #fff;
  height: 170px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  position: relative;
  
  & > div {
    &::before  {
      content: " ";
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background: #f9fafc;
      left: 0;
      top: 0;
      z-index: 2;
    }
    button, a {
      position: relative;
      z-index: 3;
      color: #97d7d8;
      font-size: 12px;
      background: none;
      border: none;
      cursor: pointer;
    }
  }
  .record {
    &__image {
      margin-bottom: 10px;
    }
    &__title {
      font-size: 16px;
      font-weight: bold;
      color: #97d7d8;
    }
    &__time {
      font-size: 12px;
      font-weight: normal;
      color: #9298ac;
      
    }
  }
`;

class MedicalRecord extends Component {
  componentDidMount() {
    const { getSharedRadiologist, patientId } = this.props;

    getSharedRadiologist(patientId);
  }
  render() {
    const {
      handelShowModal,
      medicalReport,
      allergy,
      condition,
      ecg,
      labResult,
      radiology,
      patientId,
      consultationId,
      blockConsultant,
      radiologist,
    } = this.props;

    return (
      <div>
        <MedicalRecordWrapper>
          <h1 className="medical-record__title">Medical Record</h1>
          <Row>
            <Col span={isMobile ? 12 : 8}>
              <Record>
                {condition && condition.length > 0 ? (
                  <Link
                    to={`/consultation/${consultationId}/conditions/${patientId}${
                      !blockConsultant ? `?canRequest=1` : ''
                    }`}
                  >
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${XRay2X} 2x`} />
                        <img src={XRay} alt="xray" />
                      </picture>
                    </div>
                    <h3 className="record__title">Pre-existing Conditions</h3>
                    <p className="record__time">
                      {convertDateToFullStringDate(
                        condition[condition.length - 1].updated_at
                      )}
                    </p>
                  </Link>
                ) : (
                  <div>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${XRay2X} 2x`} />
                        <img src={XRay} alt="xray" />
                      </picture>
                    </div>
                    <h3 className="record__title">Pre-existing Conditions</h3>
                    <p className="record__time">
                      Unavailable{' '}
                      {!blockConsultant && (
                        <button
                          onClick={() => {
                            handelShowModal(
                              'Request Conditions Test',
                              XRay,
                              'condition'
                            );
                          }}
                        >
                          Request
                        </button>
                      )}
                    </p>
                  </div>
                )}
              </Record>
            </Col>
            <Col span={isMobile ? 12 : 8}>
              <Record>
                {medicalReport && medicalReport.length > 0 ? (
                  <Link
                    to={`/consultation/${consultationId}/medical-reports/${patientId}${
                      !blockConsultant ? `?canRequest=1` : ''
                    }`}
                  >
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Medical2X} 2x`} />
                        <img src={Medical} alt="Medical" />
                      </picture>
                    </div>
                    <h3 className="record__title">Medical Report</h3>
                    <p className="record__time">
                      {convertDateToFullStringDate(
                        medicalReport[medicalReport.length - 1].updated_at
                      )}
                    </p>
                  </Link>
                ) : (
                  <div>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Medical2X} 2x`} />
                        <img src={Medical} alt="Medical" />
                      </picture>
                    </div>
                    <h3 className="record__title">Medical Report</h3>
                    <p className="record__time">
                      Unavailable{' '}
                      {!blockConsultant && (
                        <button
                          onClick={() => {
                            handelShowModal(
                              'Request Medical Report',
                              Medical,
                              'medical_report'
                            );
                          }}
                        >
                          Request
                        </button>
                      )}
                    </p>
                  </div>
                )}
              </Record>
            </Col>
            <Col span={isMobile ? 12 : 8}>
              <Record>
                {labResult && labResult.length > 0 ? (
                  <Link
                    to={`/consultation/${consultationId}/lab-results/${patientId}${
                      !blockConsultant ? `?canRequest=1` : ''
                    }`}
                  >
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Lab2X} 2x`} />
                        <img src={Lab} alt="Lab" />
                      </picture>
                    </div>
                    <h3 className="record__title">Lab Result</h3>
                    <p className="record__time">
                      {convertDateToFullStringDate(
                        labResult[labResult.length - 1].updated_at
                      )}
                    </p>
                  </Link>
                ) : (
                  <div>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Lab2X} 2x`} />
                        <img src={Lab} alt="Lab" />
                      </picture>
                    </div>
                    <h3 className="record__title">Lab Result</h3>
                    <p className="record__time">
                      Unavailable{' '}
                      {!blockConsultant && (
                        <button
                          onClick={() => {
                            handelShowModal(
                              'Request Lab Test',
                              Lab,
                              'lab_result'
                            );
                          }}
                        >
                          Request
                        </button>
                      )}
                    </p>
                  </div>
                )}
              </Record>
            </Col>

            <Col span={isMobile ? 12 : 8}>
              <Record>
                {allergy && allergy.length > 0 ? (
                  <Link
                    to={`/consultation/${consultationId}/allergies/${patientId}${
                      !blockConsultant ? `?canRequest=1` : ''
                    }`}
                  >
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Algro2X} 2x`} />
                        <img src={Algro} alt="Allergies" />
                      </picture>
                    </div>
                    <h3 className="record__title">Allergies</h3>
                    <p className="record__time">
                      {convertDateToFullStringDate(
                        allergy[allergy.length - 1].updated_at
                      )}
                    </p>
                  </Link>
                ) : (
                  <div>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Algro2X} 2x`} />
                        <img src={Algro} alt="Allergies" />
                      </picture>
                    </div>
                    <h3 className="record__title">Allergies</h3>
                    <p className="record__time">
                      Unavailable{' '}
                      {!blockConsultant && (
                        <button
                          onClick={() => {
                            handelShowModal(
                              'Request Allergies Test',
                              Algro,
                              'allergy'
                            );
                          }}
                        >
                          Request
                        </button>
                      )}
                    </p>
                  </div>
                )}
              </Record>
            </Col>
            <Col span={isMobile ? 12 : 8}>
              <Record>
                {radiology && radiology.xray.length < 1 ? (
                  <div to="#!">
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${XRay2X} 2x`} />
                        <img src={XRay} alt="XRay" />
                      </picture>
                    </div>
                    <h3 className="record__title">XRay</h3>
                    <p className="record__time">
                      Unavailable{' '}
                      {!blockConsultant && (
                        <button
                          onClick={() => {
                            handelShowModal(
                              'Request X ray Test',
                              XRay,
                              'radiology-xray'
                            );
                          }}
                        >
                          Request
                        </button>
                      )}
                    </p>
                  </div>
                ) : (
                  <Link
                    to={`/consultation/${consultationId}/xray/${patientId}?${
                      !blockConsultant ? `&canRequest=1` : ''
                    }`}
                  >
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${XRay2X} 2x`} />
                        <img src={XRay} alt="XRay" />
                      </picture>
                    </div>
                    <h3 className="record__title">XRay</h3>
                    <p className="record__time">
                      {convertDateToFullStringDate(
                        radiology?.xray[radiology.xray.length - 1]?.updated_at
                      )}
                    </p>
                  </Link>
                )}
              </Record>
            </Col>
            <Col span={isMobile ? 12 : 8}>
              <Record>
                {ecg && ecg.length > 0 ? (
                  <Link
                    to={`/consultation/${consultationId}/ecgs/${patientId}${
                      !blockConsultant ? `?canRequest=1` : ''
                    }`}
                  >
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Ecg2X} 2x`} />
                        <img src={Ecg} alt="ECG" />
                      </picture>
                    </div>
                    <h3 className="record__title">ECG</h3>
                    <p className="record__time">
                      {convertDateToFullStringDate(
                        ecg[ecg.length - 1].updated_at
                      )}
                    </p>
                  </Link>
                ) : (
                  <div>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Ecg2X} 2x`} />
                        <img src={Ecg} alt="ECG" />
                      </picture>
                    </div>
                    <h3 className="record__title">ECG</h3>
                    <p className="record__time">
                      Unavailable{' '}
                      {!blockConsultant && (
                        <button
                          onClick={() => {
                            handelShowModal('Request ECG Test', Ecg, 'ecg');
                          }}
                        >
                          Request
                        </button>
                      )}
                    </p>
                  </div>
                )}
              </Record>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Record>
                {radiologist && radiologist.data?.length > 0 ? (
                  <Link
                    to={`/consultation/${consultationId}/radiology/${patientId}${
                      !blockConsultant ? `?canRequest=1` : ''
                    }`}
                  >
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Redilogy2X} 2x`} />
                        <img src={Redilogy} alt="Redilogy" />
                      </picture>
                    </div>
                    <h3 className="record__title">MRI / CT Scan</h3>
                    <p className="record__time">
                      {convertDateToFullStringDate(
                        radiologist.data[radiologist.data.length - 1].updated_at
                      )}
                    </p>
                  </Link>
                ) : (
                  <div>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Redilogy2X} 2x`} />
                        <img src={Redilogy} alt="Redilogy" />
                      </picture>
                    </div>
                    <h3 className="record__title">MRI / CT Scan</h3>
                    <p className="record__time">
                      Unavailable{' '}
                      {!blockConsultant && (
                        <button
                          onClick={() => {
                            handelShowModal(
                              'Request MRI / CT Scan Test',
                              Redilogy,
                              'radiology'
                            );
                          }}
                        >
                          Request
                        </button>
                      )}
                    </p>
                  </div>
                )}
              </Record>
            </Col>
          </Row>
        </MedicalRecordWrapper>
      </div>
    );
  }
}
MedicalRecord.propTypes = {
  getSharedRadiologist: PropTypes.func.isRequired,
  handelShowModal: PropTypes.func.isRequired,
  medicalReport: PropTypes.array.isRequired,
  allergy: PropTypes.array.isRequired,
  condition: PropTypes.array.isRequired,
  ecg: PropTypes.array.isRequired,
  labResult: PropTypes.array.isRequired,
  radiology: PropTypes.object.isRequired,
  patientId: PropTypes.number,
  consultationId: PropTypes.number,
  blockConsultant: PropTypes.bool.isRequired,
  radiologist: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { radiologist } = state.patientReports;
  return {
    radiologist,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSharedRadiologist: (id) =>
    dispatch(patientReportsActions.getSharedRadiologist(id)),
});

const WrappedMedicalRecord = connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicalRecord);

export default WrappedMedicalRecord;
