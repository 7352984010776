import { consultantConstants } from 'redux/actions/types';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { loggedIn: true, user, loading: false }
  : { loading: false };

export function consultantAuth(state = initialState, action) {
  switch (action.type) {
    case consultantConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        loading: true,
      };
    case consultantConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.consultant,
        loading: false,
      };
    case consultantConstants.LOGIN_FAILURE:
      return {
        ...state,
        errors: { errors: action.error },
        loading: false,
      };
    case consultantConstants.SIGN_UP_REQUEST:
      return {
        ...state,
        signUp: true,
        loading: true,
      };
    case consultantConstants.SIGN_UP_SUCCESS:
      return {
        ...state,
        user: action.consultant,
        loading: false,
      };
    case consultantConstants.SIGN_UP_FAILURE:
      return {
        ...state,
        errors: action.error,
        loading: false,
      };
    case consultantConstants.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateProfile: true,
        loading: true,
      };
    case consultantConstants.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.consultant,
        loading: false,
      };
    case consultantConstants.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        errors: action.error,
        loading: false,
      };
    case consultantConstants.UPDATE_PROFILE_PICTURE_REQUEST:
      return {
        ...state,
        updateProfilePicture: true,
        loading: true,
      };
    case consultantConstants.UPDATE_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        user: action.consultant,
        loading: false,
      };
    case consultantConstants.UPDATE_PROFILE_PICTURE_FAILURE:
      return {
        ...state,
        errors: action.error,
        loading: false,
      };
    case consultantConstants.RESEND_CODE_REQUEST:
      return {
        ...state,
        resendCode: true,
        loading: true,
      };
    case consultantConstants.RESEND_CODE_SUCCESS:
      return {
        ...state,
        resendCode: 'success',
        loading: false,
      };
    case consultantConstants.RESEND_CODE_FAILURE:
      return {
        ...state,
        errors: action.error,
        loading: false,
      };
    case consultantConstants.GET_CONSULTATION_TYPE_REQUEST:
      return {
        ...state,
        consultationTypesRequest: true,
        loading: true,
      };
    case consultantConstants.GET_CONSULTATION_TYPE_SUCCESS:
      return {
        ...state,
        consultationTypes: action.consultationTypes,
        loading: false,
      };
    case consultantConstants.GET_CONSULTATION_TYPE_FAILURE:
      return {
        ...state,
        errors: action.error,
        loading: false,
      };
    case consultantConstants.GET_PATIENT_TYPE_REQUEST:
      return {
        ...state,
        patientTypesRequest: true,
        loading: true,
      };
    case consultantConstants.GET_PATIENT_TYPE_SUCCESS:
      return {
        ...state,
        patientTypes: action.patientTypes,
        loading: false,
      };
    case consultantConstants.GET_PATIENT_TYPE_FAILURE:
      return {
        ...state,
        errors: action.error,
        loading: false,
      };
    case consultantConstants.GET_MY_ACTIVITY_REQUEST:
      return {
        ...state,
        activityRequest: true,
        loading: true,
      };
    case consultantConstants.GET_MY_ACTIVITY_SUCCESS:
      return {
        ...state,
        activity: action.activity,
        loading: false,
      };
    case consultantConstants.GET_MY_ACTIVITY_FAILURE:
      return {
        ...state,
        errors: action.error,
        loading: false,
      };
    case consultantConstants.VERIFY_CONSULTANT_FAILURE:
    case consultantConstants.PASSWORD_RESET_CONSULTANT_FAILURE:
    case consultantConstants.PASSWORD_CHANGE_CONSULTANT_FAILURE:
      return { ...state };
    case consultantConstants.LOGOUT:
      return { loading: false };
    case consultantConstants.CLEAR_FORM:
      return { ...state, resetForm: true };
    default:
      return state;
  }
}
