import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import {
  Breadcrumb,
  H1,
  Modal,
  convertDateToFullStringDate,
} from '@mous221/hk-utils';
import { Container, RequestMedicalRecordForm } from '@mous221/hk-components';
import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import { CTScan, CTScan2X, MRI, MRI2X, XRay, XRay2X } from 'images';
import { consultationActions } from 'redux/actions';
import { isMobile } from 'react-device-detect';
import { device } from 'utils';
const RadiologistWrapper = styled.div`
  min-height: 65vh;
  max-width: 800px;
  margin: auto;
  h1 {
    font-size: 30px;
  }
  a {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #9298ac;
  }
  .ant-breadcrumb-separator {
    margin-right: 5px;
    color: #9298ac;
  }
  .add {
    margin-bottom: 25px;
  }
  .pagination {
    max-width: 780px;
    margin-left: 0;
  }
`;

const Record = styled.div`
  width: 95%;
  background #fff;
  height: 170px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  position: relative;
  a {
    display: block;
    width: 100%;
  }
  & > div {
    &::before  {
      content: " ";
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background: #f9fafc;
      left: 0;
      top: 0;
      z-index: 2;
    }
    a {
      position: relative;
      display: block;
      width: 100%;
      z-index: 3;
      color: #97d7d8;
      font-size: 12px;
    }
  }
  .record {
    &__image {
      margin-bottom: 10px;
    }
    &__title {
      font-size: 16px;
      font-weight: bold;
      color: #97d7d8;
    }
    &__time {
      font-size: 12px;
      font-weight: normal;
      color: #9298ac;
    }
  }
`;

const Wrapper = styled.div`
  .modal__content {
    @media ${device.allMobile} {
      width: 80%;
    }
  }
`;

export class Radiologist extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    requestScan: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  state = {
    showModal: false,
    modalTitle: '',
    modalImage: '',
    scanType: '',
  };

  handelShowModal = (title, image, scanType) => {
    this.setState({
      showModal: true,
      modalTitle: title,
      modalImage: image,
      scanType,
    });
  };
  handelCloseModal = () => {
    this.setState({ showModal: false });
  };
  render() {
    const {
      match: {
        params: { consultationId, patientId },
      },
      location,
      requestScan,
    } = this.props;
    const { showModal, modalTitle, modalImage, scanType } = this.state;
    let mriUrl = '';
    let ctUrl = '';
    let xrayUrl = '';
    const radiologistPrams = JSON.parse(
      new URLSearchParams(location.search).get('radiologistPrams')
    );
    const canRequest = new URLSearchParams(location.search).get('canRequest');
    if (consultationId && patientId) {
      mriUrl = `/consultation/${consultationId}/mri/${patientId}?radiologistPrams=${JSON.stringify(
        radiologistPrams
      )}${canRequest ? `&canRequest=1` : ''}`;
      ctUrl = `/consultation/${consultationId}/ct/${patientId}?radiologistPrams=${JSON.stringify(
        radiologistPrams
      )}${canRequest ? `&canRequest=1` : ''}`;
      xrayUrl = `/consultation/${consultationId}/xray/${patientId}?radiologistPrams=${JSON.stringify(
        radiologistPrams
      )}${canRequest ? `&canRequest=1` : ''}`;
    }

    return (
      <Wrapper>
        <Container>
          <RadiologistWrapper>
            <Breadcrumb
              links={[
                {
                  item: 'Medical Record ',
                  url: `/consultation/${consultationId}`,
                },
              ]}
            />
            <H1>Radiology</H1>
            <Row>
              <Col span={isMobile ? 12 : 8}>
                <Record>
                  {radiologistPrams && radiologistPrams.mri ? (
                    <Link to={mriUrl}>
                      <div className="record__image">
                        <picture>
                          <source srcSet={`${MRI2X} 2x`} />
                          <img src={MRI} alt="MRI" />
                        </picture>
                      </div>
                      <h3 className="record__title">MRI</h3>
                      <p className="record__time">
                        {convertDateToFullStringDate(
                          radiologistPrams.mri.lastTime
                        )}
                      </p>
                    </Link>
                  ) : (
                    <div to="#!">
                      <div className="record__image">
                        <picture>
                          <source srcSet={`${MRI2X} 2x`} />
                          <img src={MRI} alt="MRI" />
                        </picture>
                      </div>
                      <h3 className="record__title">MRI</h3>
                      <p className="record__time">
                        Unavailable{' '}
                        {canRequest && (
                          <Link
                            to="#!"
                            onClick={() => {
                              this.handelShowModal(
                                'Request MRI Test',
                                MRI,
                                'radiology-mri'
                              );
                            }}
                          >
                            Request
                          </Link>
                        )}
                      </p>
                    </div>
                  )}
                </Record>
              </Col>
              <Col span={isMobile ? 12 : 8}>
                <Record>
                  {radiologistPrams && radiologistPrams.ct ? (
                    <Link to={ctUrl}>
                      <div className="record__image">
                        <picture>
                          <source srcSet={`${CTScan2X} 2x`} />
                          <img src={CTScan} alt="CTScan" />
                        </picture>
                      </div>
                      <h3 className="record__title">CT Scan</h3>
                      <p className="record__time">
                        {convertDateToFullStringDate(
                          radiologistPrams.ct.lastTime
                        )}
                      </p>
                    </Link>
                  ) : (
                    <div to="#!">
                      <div className="record__image">
                        <picture>
                          <source srcSet={`${CTScan2X} 2x`} />
                          <img src={CTScan} alt="CTScan" />
                        </picture>
                      </div>
                      <h3 className="record__title">CT Scan</h3>
                      <p className="record__time">
                        Unavailable{' '}
                        {canRequest && (
                          <Link
                            to="#!"
                            onClick={() => {
                              this.handelShowModal(
                                'Request CT Scan Test',
                                CTScan,
                                'radiology-ct'
                              );
                            }}
                          >
                            Request
                          </Link>
                        )}
                      </p>
                    </div>
                  )}
                </Record>
              </Col>
              <Col span={isMobile ? 12 : 8}>
                <Record>
                  {radiologistPrams && radiologistPrams.xray ? (
                    <Link to={xrayUrl}>
                      <div className="record__image">
                        <picture>
                          <source srcSet={`${XRay2X} 2x`} />
                          <img src={XRay} alt="XRay" />
                        </picture>
                      </div>
                      <h3 className="record__title">XRay</h3>
                      <p className="record__time">
                        {convertDateToFullStringDate(
                          radiologistPrams.xray.lastTime
                        )}
                      </p>
                    </Link>
                  ) : (
                    <div to="#!">
                      <div className="record__image">
                        <picture>
                          <source srcSet={`${XRay2X} 2x`} />
                          <img src={XRay} alt="XRay" />
                        </picture>
                      </div>
                      <h3 className="record__title">XRay</h3>
                      <p className="record__time">
                        Unavailable{' '}
                        {canRequest && (
                          <Link
                            to="#!"
                            onClick={() => {
                              this.handelShowModal(
                                'Request XRay Test',
                                XRay,
                                'radiology-xray'
                              );
                            }}
                          >
                            Request
                          </Link>
                        )}
                      </p>
                    </div>
                  )}
                </Record>
              </Col>
            </Row>
          </RadiologistWrapper>
        </Container>
        <Modal
          title={modalTitle}
          image={modalImage}
          showModal={showModal}
          handelCloseModal={this.handelCloseModal}
        >
          <>
            <RequestMedicalRecordForm
              requestScan={requestScan}
              scanType={scanType}
              consultationId={consultationId}
              handelCloseModal={this.handelCloseModal}
            />
          </>
        </Modal>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  requestScan: ({ scanType, id, title, body }) =>
    dispatch(consultationActions.requestScan({ scanType, id, title, body })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radiologist);
