import React, { Component } from 'react';
import { connect } from 'react-redux';
import Book from '../Book';
import PropTypes from 'prop-types';
import { schedulesActions } from 'redux/actions';
import { More, H1 } from '@mous221/hk-utils';
import styled from '@emotion/styled/macro';

const BookAppointmentWrapper = styled.div`
  margin-bottom: 10px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  border-radius: 6px;
  .book-wrapper:last-child {
    border-radius: 0 0 6px 6px;
  }
`;

const BookAppointmentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 18px;
  background-color: #f9fafc;
  border-radius: 6px 6px 0 0;
  h1 {
    font-size: 18px;
  }
`;
class BookAppointment extends Component {
  componentDidMount() {
    const { getMyBookedAppointments } = this.props;

    getMyBookedAppointments();
  }
  render() {
    const { appointments } = this.props;

    const appointmentsComponents =
      appointments &&
      appointments.data.slice(0, 3).map((appointment) => {
        return (
          <Book key={JSON.stringify(appointment)} appointment={appointment} />
        );
      });
    return (
      <BookAppointmentWrapper>
        <BookAppointmentHeader>
          <H1>Appointments</H1>
          {appointments && appointments.data && (
            <div>
              <More to="/my-appointment" />
            </div>
          )}
        </BookAppointmentHeader>
        {appointmentsComponents}
      </BookAppointmentWrapper>
    );
  }
}
const mapStateToProps = (state) => {
  const { appointments } = state.schedules;
  return {
    appointments,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMyBookedAppointments: (page = null) =>
    dispatch(schedulesActions.getMyBookedAppointments(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookAppointment);

BookAppointment.propTypes = {
  appointments: PropTypes.object,
  getMyBookedAppointments: PropTypes.func.isRequired,
};
