import React, { Component } from 'react';
import { Row, Col, Form } from 'antd';
import styled from '@emotion/styled/macro';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { HandleAppointmentDate } from 'utils';
import { Container } from '@mous221/hk-components';
import { consultantActions, schedulesActions } from 'redux/actions';
import {
  UserProfileCard,
  UserProfileNavigation,
  UserInfo,
  MyAddress,
  ChangePassword,
  Payment,
  AddPayment,
  AboutMe,
  Schedules,
} from './index';

const ProfilePageWrapper = styled.div`
  .profile-border {
    box-shadow: 2px 3px 4px 2px rgba(1, 2, 2, 0.02);
    border-radius: 18px;
    border: solid 1px #e4e9f2;
    padding: 33px;
    background-color: #fff;
    &.ant-col {
      padding: 0;
      border: none;
      border-radius: 6px;
    }
  }
`;

class ProfilePage extends Component {
  componentDidMount() {
    const {
      consultationTypes,
      patientTypes,
      schedules,
      getPatientTypes,
      getConsultationTypes,
      getSchedules,
    } = this.props;
    if (!consultationTypes) {
      getConsultationTypes();
    }
    if (!patientTypes) {
      getPatientTypes();
    }
    if (!schedules) {
      getSchedules();
    }
  }
  state = {
    currentView: 'userInfo',
    picture: false,
    editMyInfo: false,
    editMyAddress: false,
    editSchedules: false,
    editAboutMe: false,
  };

  handleEditSchedules = () => {
    this.setState({ editSchedules: !this.state.editSchedules });
  };
  handleEditMyInfoToggle = () => {
    this.setState({ editMyInfo: !this.state.editMyInfo });
  };

  handleEditMyAddressToggle = () => {
    this.setState({ editMyAddress: !this.state.editMyAddress });
  };

  handleEditAboutMeToggle = () => {
    this.setState({ editAboutMe: !this.state.editAboutMe });
  };

  setProfilePic = (pic) => {
    this.setState({ picture: pic });
  };

  setCurrentView = (show) => {
    this.setState({ currentView: show });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      form: { validateFields },
      updateProfile,
    } = this.props;
    const { picture } = this.state;
    validateFields((err, values) => {
      if (!err) {
        values.consultationTypes = [values.consultationTypes];
        values.dateOfBirth = moment(values.dateOfBirth).unix();

        values.address = {
          country: values.country,
          city: values.city,
          street: values.street,
          postel_code: values.postalCode,
        };
        if (picture) {
          values.picture = values.picture.fileList[0].originFileObj;
        } else {
          values.picture = null;
        }
        updateProfile(values);
        this.setState({
          editMyInfo: false,
          editMyAddress: false,
          editAboutMe: false,
          editSchedules: false,
          picture: false,
        });
      }
    });
  };

  handleSchedulesSubmit = (e) => {
    e.preventDefault();
    const {
      form: { validateFields },
      updateSchedules,
    } = this.props;

    validateFields((err, values) => {
      if (!err) {
        const {
          AppointmentTime,
          AppointmentTimeFrom,
          AppointmentTimeTo,
          AppointmentTimeDay,
          AppointmentTimeId,
        } = values;

        const appointmentTimes = AppointmentTime.map((key) => {
          const to_time = HandleAppointmentDate(
            AppointmentTimeTo[key],
            AppointmentTimeDay[key]
          );
          const from_time = HandleAppointmentDate(
            AppointmentTimeFrom[key],
            AppointmentTimeDay[key]
          );

          return {
            id: AppointmentTimeId[key] || null,
            day: AppointmentTimeDay[key],
            to_time,
            from_time,
          };
        });

        updateSchedules({ schedules: appointmentTimes });
        this.setState({ editSchedules: false });
      }
    });
  };
  render() {
    const {
      currentView,
      editMyInfo,
      editMyAddress,
      editAboutMe,
      editSchedules,
    } = this.state;

    const {
      updateProfile,
      changePassword,
      form,
      patientTypes,
      consultationTypes,
      schedules,
    } = this.props;
    const user = JSON.parse(localStorage.getItem('user'));
    console.log('Schedules 1', schedules);
    return (
      <Container className="profile">
        <ProfilePageWrapper>
          <Row>
            <Col className="profile-border" span={6}>
              <UserProfileCard user={user} />
              <UserProfileNavigation setCurrentView={this.setCurrentView} />
            </Col>
            {currentView === 'userInfo' && (
              <Col span={17} offset={1}>
                <Form onSubmit={this.handleSubmit}>
                  <UserInfo
                    user={user}
                    updateProfile={updateProfile}
                    form={form}
                    setProfilePic={this.setProfilePic}
                    editFormIsOpen={editMyInfo}
                    handleFormToggle={this.handleEditMyInfoToggle}
                  />
                  <MyAddress
                    user={user}
                    form={form}
                    editFormIsOpen={editMyAddress}
                    handleFormToggle={this.handleEditMyAddressToggle}
                  />
                  {consultationTypes && patientTypes && (
                    <AboutMe
                      user={user}
                      form={form}
                      consultationTypes={consultationTypes}
                      patientTypes={patientTypes}
                      editFormIsOpen={editAboutMe}
                      handleFormToggle={this.handleEditAboutMeToggle}
                    />
                  )}
                </Form>
                <Form onSubmit={this.handleSchedulesSubmit}>
                  {schedules && (
                    <Schedules
                      form={form}
                      editFormIsOpen={editSchedules}
                      handleFormToggle={this.handleEditSchedules}
                      schedules={schedules.data}
                    />
                  )}
                </Form>
              </Col>
            )}
            {currentView === 'Payment' && (
              <Col span={17} offset={1}>
                <Payment setCurrentView={this.setCurrentView} />
              </Col>
            )}
            {currentView === 'changePassword' && (
              <Col span={17} offset={1}>
                <ChangePassword changePassword={changePassword} />
              </Col>
            )}
            {currentView === 'addPayment' && (
              <Col span={17} offset={1}>
                <AddPayment />
              </Col>
            )}
          </Row>
        </ProfilePageWrapper>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { consultationTypes, patientTypes, user } = state.consultantAuth;
  const { schedules } = state.schedules;
  return {
    user,
    consultationTypes,
    patientTypes,
    schedules,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateProfile: (consultant) =>
    dispatch(consultantActions.updateProfile(consultant)),
  changePassword: ({ oldPassword, password }) =>
    dispatch(consultantActions.changePassword({ oldPassword, password })),
  updateProfilePicture: (picture) =>
    dispatch(consultantActions.updateProfilePicture(picture)),
  getPatientTypes: () => dispatch(consultantActions.getPatientType()),
  getConsultationTypes: () => dispatch(consultantActions.getConsultationType()),
  getSchedules: () => dispatch(schedulesActions.getSchedules()),
  updateSchedules: (schedules) =>
    dispatch(schedulesActions.updateSchedules(schedules)),
});

const WrappedProfilePage = Form.create({ name: 'update_profile_form' })(
  connect(mapStateToProps, mapDispatchToProps)(ProfilePage)
);

export default WrappedProfilePage;
ProfilePage.propTypes = {
  updateProfile: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  getPatientTypes: PropTypes.func.isRequired,
  getConsultationTypes: PropTypes.func.isRequired,
  consultationTypes: PropTypes.array,
  patientTypes: PropTypes.array,
  getSchedules: PropTypes.func.isRequired,
  updateSchedules: PropTypes.func.isRequired,
  schedules: PropTypes.object,
};
