import React from 'react';
import { connect } from 'react-redux';
import { consultantActions } from 'redux/actions';
import { MainLayout } from '@mous221/hk-components';
import { Profile } from './components';

const ProfilePage = (props) => (
  <MainLayout footer="main" children={<Profile {...props} />} {...props} />
);

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(consultantActions.logout()),
});

export default connect(() => ({}), mapDispatchToProps)(ProfilePage);
