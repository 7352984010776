import alertConstants from './types';

const success = (message) => {
  return { type: alertConstants.SUCCESS, message };
};

const info = (message) => {
  return { type: alertConstants.INFO, message };
};

const warning = (message) => {
  return { type: alertConstants.WARNING, message };
};

const error = (message) => {
  return { type: alertConstants.ERROR, message };
};

const clear = () => {
  return { type: alertConstants.CLEAR };
};

export const alertActions = {
  success,
  info,
  warning,
  error,
  clear,
};
