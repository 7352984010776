import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as scenes from 'scenes';
import { alertActions } from 'redux/actions';
import { GlobalStyles, history } from 'utils';
import { PrivateRoute } from './PrivateRoute';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'utils/fetchIntercept';
class App extends Component {
  constructor(props) {
    super(props);

    const { clearAlerts } = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      clearAlerts();
    });
  }
  state = {};
  static getDerivedStateFromProps({ alert }, state) {
    switch (alert.type) {
      case 'success':
        NotificationManager.success(alert.message);
        break;
      case 'warning':
        NotificationManager.warning(alert.message);
        break;
      case 'error':
        NotificationManager.error(alert.message);
        break;
      case 'info':
        NotificationManager.info(alert.message);
        break;
      default:
        break;
    }
    return {};
  }
  render() {
    return (
      <div>
        <GlobalStyles />
        <Router history={history}>
          <div>
            <Route path="/login" component={scenes.ConsultantLoginPage} />
            <Route path="/register" component={scenes.ConsultantRegisterPage} />
            <Route
              path="/verify-mobile/:mobileNum"
              component={scenes.VerifyMobilePage}
            />
            <PrivateRoute
              exact
              path="/reports"
              component={scenes.ReportsPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:id"
              component={scenes.ViewConsultationPage}
            />
            <PrivateRoute
              exact
              path="/"
              component={scenes.ConsultantHomePage}
            />
            <PrivateRoute
              exact
              path="/profile"
              component={scenes.ConsultantProfilePage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/radiology/mri/:id"
              component={scenes.MRIPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/mri/:patientId"
              component={scenes.MRIsPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/ct/:patientId"
              component={scenes.CTsPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/xray/:patientId"
              component={scenes.XRaysPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/xray/:patientId/:id"
              component={scenes.XRayPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/radiologist/:patientId"
              component={scenes.PatientRadiologistPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/ecgs/:patientId"
              component={scenes.PatientECGsPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/ecg/:id"
              component={scenes.PatientECGPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/lab-results/:patientId"
              component={scenes.PatientLabResultsPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/lab-result/:id"
              component={scenes.PatientLabResultPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/medical-reports/:patientId"
              component={scenes.PatientMedicalReportsPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/medical-report/:id"
              component={scenes.PatientMedicalReportPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/allergy/:id"
              component={scenes.PatientAllergyPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/allergies/:patientId"
              component={scenes.PatientAllergiesPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/condition/:id"
              component={scenes.PatientConditionPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/conditions/:patientId"
              component={scenes.PatientConditionsPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/radiology/:patientId"
              component={scenes.RadiologistListPage}
            />
            <PrivateRoute
              exact
              path="/my-appointment"
              component={scenes.AppointmentsPage}
            />
            <PrivateRoute
              exact
              path="/my-activity"
              component={scenes.MyActivity}
            />
          </div>
        </Router>
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { alert } = state;
  return {
    alert,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearAlerts: () => dispatch(alertActions.clear()),
});

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
export default connectedApp;

App.propTypes = {
  clearAlerts: PropTypes.func,
  alert: PropTypes.object,
};
