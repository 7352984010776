import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Lab, PlusIcon } from 'images';
import {
  Breadcrumb,
  H1,
  Pagination,
  StateCard,
  Modal,
} from '@mous221/hk-utils';
import { patientReportsActions, consultationActions } from 'redux/actions';
import { Container, RequestMedicalRecordForm } from '@mous221/hk-components';
import styled from '@emotion/styled/macro';
import { device } from 'utils';

const RadiologistWrapper = styled.div`
  h1 {
    font-size: 30px;
  }
  a {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #9298ac;
  }
  .ant-breadcrumb-separator {
    margin-right: 5px;
    color: #9298ac;
  }
  .add {
    margin-bottom: 25px;
  }
  .pagination {
    max-width: 780px;
    margin-left: 0;
  }
  .request {
    display: block;
    margin: 15px 0;
    color: #97d7d8;
    border: none;
    background: none;
    cursor: pointer;
    img {
      margin-right: 10px;
      width: 25px;
      height: 25px;
      margin-top: 20px;
      position: relative;
      top: -11px;
    }
  }
  .modal__content {
    @media ${device.allMobile} {
      width: 80%;
    }
  }
`;

class Radiologist extends Component {
  componentDidMount() {
    const {
      match: {
        params: { patientId },
      },
      getSharedRadiologist,
    } = this.props;

    if (patientId) {
      getSharedRadiologist(patientId);
    }
  }
  state = {
    showModal: false,
  };

  handelCloseModal = () => {
    this.setState({ showModal: false });
  };

  handelShowModal = () => {
    this.setState({ showModal: true });
  };

  setPage = (page) => {
    const {
      match: {
        params: { patientId },
      },
      getSharedRadiologist,
    } = this.props;

    if (patientId) {
      getSharedRadiologist(patientId, null, page);
    }
  };

  render() {
    const {
      reports: radiology,
      match: {
        params: { consultationId, patientId },
      },
      requestScan,
      location,
    } = this.props;
    const { showModal } = this.state;

    const BreadcrumbLinks = consultationId
      ? [
          {
            item: 'MRI / CT Scan ',
            url: `/consultation/${consultationId}`,
          },
        ]
      : '';
    const canRequest = new URLSearchParams(location.search).get('canRequest');
    return (
      <Container>
        <RadiologistWrapper>
          <Breadcrumb links={BreadcrumbLinks} />
          <H1>Radiologist</H1>
          {canRequest && (
            <button
              className="request"
              to="#!"
              onClick={() => this.handelShowModal()}
            >
              <img src={PlusIcon} alt="Plus" />
              Request Medical Reports
            </button>
          )}
          {radiology &&
            radiology.data.map((report) => (
              <StateCard
                location={report.viewer_url}
                key={report.updated_at}
                title={report.title}
                time={report.updated_at}
                patientId={patientId}
                item={report}
              />
            ))}
          {radiology && radiology.meta.total > radiology.meta.per_page && (
            <Pagination
              pageSize={radiology.meta.per_page}
              total={radiology.meta.total}
              setPage={this.setPage}
            />
          )}
          <Modal
            title={'Request Medical Reports'}
            image={Lab}
            showModal={showModal}
            handelCloseModal={this.handelCloseModal}
          >
            <>
              <RequestMedicalRecordForm
                requestScan={requestScan}
                scanType={'radiology'}
                consultationId={Number(consultationId)}
                handelCloseModal={this.handelCloseModal}
              />
            </>
          </Modal>
        </RadiologistWrapper>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { reports } = state.patientReports;
  return {
    reports,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSharedRadiologist: (patientId, sharedToken, page = null) =>
    dispatch(
      patientReportsActions.getSharedRadiologist(patientId, sharedToken, page)
    ),
  requestScan: ({ scanType, id, title, body }) =>
    dispatch(consultationActions.requestScan({ scanType, id, title, body })),
});

Radiologist.propTypes = {
  reports: PropTypes.object,
  match: PropTypes.object.isRequired,
  getSharedRadiologist: PropTypes.func.isRequired,
  requestScan: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Radiologist);
