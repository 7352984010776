import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { schedulesActions } from 'redux/actions';
import styled from '@emotion/styled/macro';
import { Calendar, Row, Col, Spin } from 'antd';
import { H1, Colors } from '@mous221/hk-utils';
import { Container } from '@mous221/hk-components';
import Book from './Book';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { device } from 'utils';
const CalendarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 37px 56px 37px 47px;
  background-color: ${Colors.white};
  @media ${device.allMobile} {
    padding: 15px;
    margin-bottom: 25px;
  }
  .ant-fullcalendar.ant-fullcalendar-full:focus {
    outline: none;
  }
  .ant-fullcalendar-disabled-cell {
    .ant-fullcalendar-value {
      color: ${Colors.gary};
    }
  }
  tr {
    display: flex;
    justify-content: space-between;
  }
  & > div {
    width: 100%;
    .ant-select-selection {
      border: none;
      font-size: 25px;
      font-weight: 500;
    }
    .ant-fullcalendar-year-select {
      order: 2;
    }
  }
  .ant-fullcalendar-header {
    display: flex;
    justify-content: center;
    .ant-radio-button-wrapper {
      display: none;
    }
  }
  .ant-fullcalendar-date {
    position: relative;
    .ant-fullcalendar-content {
      .events {
        padding: 0;
        list-style: none;
        li {
          position: absolute;
          width: 7px;
          height: 7px;
          background-color: ${Colors.secondaryColor};
          border-radius: 50%;
          left: 50%;
          bottom: -10px;
          transform: translateX(-50%);
        }
      }
    }
  }
  .ant-fullcalendar-disabled-cell {
    .events {
      display: none;
    }
  }
  .ant-fullcalendar-cell {
    width: 44px;
    height: 44px;
    text-align: center;
    cursor: pointer;
  }
  .ant-fullcalendar-value {
    line-height: 44px;
  }
  .ant-fullcalendar-selected-day {
    background-color: ${Colors.secondaryColor};
    cursor: pointer;
    border-radius: 50%;
    color: ${Colors.white};
    .ant-fullcalendar-content {
      display: none;
    }
  }
  .ant-fullcalendar-calendar-body {
    .ant-fullcalendar-cell {
      margin-bottom: 28px;
    }
    .ant-fullcalendar-table {
      width: 100%;
      .ant-fullcalendar-column-header-inner {
        margin-bottom: 40px;
        margin-top: 30px;
        display: inline-block;
        width: 44px;
        text-align: center;
      }
      .ant-fullcalendar-column-header-inner,
      .ant-fullcalendar-value {
        font-size: 22px;
      }
    }
  }
  .ant-select-selection--single .ant-select-selection__rendered {
    margin-right: 27px;
  }
`;

const AppointmentsWrapper = styled.div`
  margin: auto;
  .book-wrapper {
    border-radius: 10px;
    margin-bottom: 15px;
  }
  h1 {
    font-size: 30px;
    margin-bottom: 25px;
    @media ${device.allMobile} {
      font-size: 24px;
      margin-top: 15px;
    }
  }
`;

const PatientAppointments = styled.div`
  padding: 0 20px;
`;
class Appointments extends Component {
  state = {
    month: moment().clone().startOf('month').format('X'),
    appointmentsPerDay: [],
  };
  componentDidMount() {
    const {
      getMyBookedAppointments,
      getAppointmentsPerDay,
      location: { search },
    } = this.props;
    const day = new URLSearchParams(search).get('day');

    if (day && moment.unix(day).isAfter(moment())) {
      this.onChange(moment.unix(day));
      getAppointmentsPerDay(moment.unix(day));
    }

    const thisMonth = moment().clone().startOf('month').format('X');
    getMyBookedAppointments(thisMonth, moment());
  }

  dateCellRender = (value) => {
    const { days } = this.props;
    const listData = [];
    if (days.includes(value.format('MM-DD-YYYY'))) {
      listData.push(1);
    }
    return (
      <ul className="events">
        {listData.map(() => (
          <li key={Date.now()}></li>
        ))}
      </ul>
    );
  };

  onChange = (day) => {
    const { getAppointmentsPerDay } = this.props;
    if (day.isAfter(moment().startOf('day'))) {
      getAppointmentsPerDay(day);
    }
  };

  onPanelChange = (value) => {
    const { getMyBookedAppointments } = this.props;
    const { month } = this.state;
    const panelMonth = value.clone().startOf('month').format('X');

    if (month !== panelMonth) {
      this.setState({ month: panelMonth });
      return getMyBookedAppointments(panelMonth, value);
    } else {
      return;
    }
  };
  disabledDate = (current) => {
    return current && current < moment().subtract(1, 'days').endOf('yesterday');
  };
  render() {
    const {
      loading,
      appointmentsPerDay,
      location: { search },
    } = this.props;
    const day = new URLSearchParams(search).get('day');

    return (
      <Container>
        <AppointmentsWrapper>
          <H1>Upcoming Appointments</H1>

          <Row>
            <Col span={isMobile ? 24 : 12}>
              <CalendarWrapper>
                <Spin spinning={loading}>
                  <Calendar
                    defaultValue={day ? moment.unix(day) : moment()}
                    fullscreen={false}
                    dateCellRender={(val) => this.dateCellRender(val)}
                    onSelect={this.onChange}
                    onPanelChange={this.onPanelChange}
                    disabledDate={this.disabledDate}
                  />
                </Spin>
              </CalendarWrapper>
            </Col>
            <Col span={isMobile ? 24 : 12}>
              <PatientAppointments>
                {appointmentsPerDay.length > 0 &&
                  appointmentsPerDay.map((appointment) => (
                    <Book
                      appointment={appointment}
                      key={JSON.stringify(appointment)}
                    />
                  ))}
              </PatientAppointments>
            </Col>
          </Row>
        </AppointmentsWrapper>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { appointments, loading, days, appointmentsPerDay } = state.schedules;
  return {
    appointments,
    loading,
    days,
    appointmentsPerDay,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMyBookedAppointments: (month, day = null) =>
    dispatch(schedulesActions.getMyBookedAppointments(month, day)),
  getAppointmentsPerDay: (day) =>
    dispatch(schedulesActions.getAppointmentsPerDay(day)),
});

Appointments.propTypes = {
  days: PropTypes.array.isRequired,
  appointments: PropTypes.object.isRequired,
  getMyBookedAppointments: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  appointmentsPerDay: PropTypes.array.isRequired,
  getAppointmentsPerDay: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
