import { history } from 'utils';
import {
  alertActions,
  consultationActions,
  schedulesActions,
  patientReportsActions,
} from 'redux/actions';
import { consultantServices } from 'services';
import consultantConstants from './types';

const login = (email, password) => {
  return (dispatch) => {
    dispatch(loginRequest({ email }));

    consultantServices.login(email, password).then((res) => {
      if (res.error) {
        dispatch(loginFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(loginSuccess(res));
        dispatch(alertActions.clear());
        history.push('/');
      }
    });
  };
};

const register = (consultant) => {
  return (dispatch) => {
    dispatch(signupRequest(consultant));

    consultantServices.register(consultant).then((res) => {
      if (res.error) {
        dispatch(signupFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(signupSuccess(res.consultant));
        dispatch(clearForm());
        dispatch(
          alertActions.success(
            'A verification link has been sent to your email!'
          )
        );
        dispatch(alertActions.clear());
        history.push(`/verify-mobile/${res.consultant.mobile}`);
      }
    });
  };
};

const updateProfile = (consultant) => {
  return (dispatch) => {
    dispatch(updateProfileRequest(consultant));

    consultantServices.updateProfile(consultant).then((res) => {
      if (res.error) {
        dispatch(updateProfileFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(updateProfileSuccess(res.consultant));
        dispatch(clearForm());
        dispatch(alertActions.success('Your profile has been updated'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const updateProfilePicture = (consultant) => {
  return (dispatch) => {
    dispatch(updateProfilePictureRequest(consultant));
    consultantServices.register(consultant).then((res) => {
      if (res.error) {
        dispatch(updateProfilePictureFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(updateProfilePictureSuccess(res.consultant));
        dispatch(clearForm());
      }
    });
  };
};

const verify = ({ code }) => {
  return (dispatch) => {
    dispatch(verificationRequest());

    consultantServices.verify({ code }).then((res) => {
      if (res.error) {
        res.errors.map((err) => dispatch(alertActions.error(err)));
        verificationFailure(res.errors);
        dispatch(alertActions.clear());
      } else {
        dispatch(verificationSuccess(res));
        dispatch(alertActions.success('verification success, login now!'));
        dispatch(alertActions.clear());
        history.push('/');
      }
    });
  };
};

const resendCode = (token) => {
  return (dispatch) => {
    dispatch(resendCodeRequest(token));

    consultantServices.resendCode(token).then((res) => {
      if (res.error) {
        dispatch(resendCodeFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(resendCodeSuccess(res.res));
        dispatch(clearForm());
        dispatch(alertActions.success('Send success'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const resetPassword = (email) => {
  return (dispatch) => {
    dispatch(passwordResetRequest());
    consultantServices.resetPassword(email).then(
      ({ success }) => {
        dispatch(passwordResetSuccess());
        dispatch(alertActions.success('password reset email sent!'));
        dispatch(alertActions.clear());
        dispatch(clearForm());
      },
      (error) => {
        dispatch(passwordResetFailure(error));
        error.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      }
    );
  };
};

const changePassword = ({ oldPassword, password }) => {
  return (dispatch) => {
    dispatch(passwordChangeRequest());
    consultantServices.changePassword({ oldPassword, password }).then((res) => {
      if (res.error) {
        passwordChangeFailure(res.errors);
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(passwordChangeSuccess());
        dispatch(alertActions.success('password successfully changed!'));
        dispatch(alertActions.clear());
        dispatch(clearForm());
      }
    });
  };
};

const getConsultationType = () => {
  return (dispatch) => {
    dispatch(getConsultationTypeRequest());

    consultantServices.getConsultationType().then((res) => {
      if (res.error) {
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(getConsultationTypeFailure(res.errors));
      } else {
        dispatch(getConsultationTypeSuccess(res.consultationTypes));
      }
    });
  };
};

const getPatientType = () => {
  return (dispatch) => {
    dispatch(getPatientTypeRequest());
    consultantServices.getPatientType().then((res) => {
      if (res.error) {
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(getPatientTypeFailure(res.errors));
      } else {
        dispatch(getPatientTypeSuccess(res.patientTypes));
      }
    });
  };
};

const getMyActivity = () => {
  return (dispatch) => {
    dispatch(getMyActivityRequest());
    consultantServices.getMyActivity().then((res) => {
      if (res.error) {
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(getMyActivityFailure(res.errors));
      } else {
        dispatch(getMyActivitySuccess(res.activity));
      }
    });
  };
};

const logout = () => {
  history.push('/login');
  consultantServices.logout();
  return (dispatch) => {
    dispatch(alertActions.clear());
    dispatch(consultationActions.clearData());
    dispatch(schedulesActions.clearData());
    dispatch(patientReportsActions.clearData());

    dispatch(logoutSuccess());
  };
};

const logoutSuccess = () => ({ type: consultantConstants.LOGOUT });
const loginRequest = (consultant) => ({
  type: consultantConstants.LOGIN_REQUEST,
  consultant,
});
const loginSuccess = (consultant) => ({
  type: consultantConstants.LOGIN_SUCCESS,
  consultant,
});
const loginFailure = (error) => ({
  type: consultantConstants.LOGIN_FAILURE,
  error,
});

const signupRequest = (consultant) => ({
  type: consultantConstants.SIGN_UP_REQUEST,
  consultant,
});
const signupSuccess = (consultant) => ({
  type: consultantConstants.SIGN_UP_SUCCESS,
  consultant,
});
const signupFailure = (error) => ({
  type: consultantConstants.SIGN_UP_FAILURE,
  error,
});

const updateProfileRequest = (consultant) => ({
  type: consultantConstants.UPDATE_PROFILE_REQUEST,
  consultant,
});

const updateProfileSuccess = (consultant) => ({
  type: consultantConstants.UPDATE_PROFILE_SUCCESS,
  consultant,
});
const updateProfileFailure = (error) => ({
  type: consultantConstants.UPDATE_PROFILE_FAILURE,
  error,
});

const updateProfilePictureRequest = () => ({
  type: consultantConstants.UPDATE_PROFILE_PICTURE_REQUEST,
});

const updateProfilePictureSuccess = (consultant) => ({
  type: consultantConstants.UPDATE_PROFILE_PICTURE_SUCCESS,
  consultant,
});
const updateProfilePictureFailure = (error) => ({
  type: consultantConstants.UPDATE_PROFILE_PICTURE_FAILURE,
  error,
});

const verificationRequest = (consultant) => ({
  type: consultantConstants.VERIFY_CONSULTANT_REQUEST,
  consultant,
});
const verificationSuccess = (consultant) => ({
  type: consultantConstants.VERIFY_CONSULTANT_SUCCESS,
  consultant,
});
const verificationFailure = (error) => ({
  type: consultantConstants.VERIFY_CONSULTANT_FAILURE,
  error,
});
const resendCodeRequest = (consultant) => ({
  type: consultantConstants.RESEND_CODE_REQUEST,
  consultant,
});
const resendCodeSuccess = (consultant) => ({
  type: consultantConstants.RESEND_CODE_SUCCESS,
  consultant,
});
const resendCodeFailure = (error) => ({
  type: consultantConstants.RESEND_CODE_FAILURE,
  error,
});

const passwordResetRequest = (consultant) => ({
  type: consultantConstants.PASSWORD_RESET_CONSULTANT_REQUEST,
  consultant,
});
const passwordResetSuccess = (consultant) => ({
  type: consultantConstants.PASSWORD_RESET_CONSULTANT_SUCCESS,
  consultant,
});
const passwordResetFailure = (error) => ({
  type: consultantConstants.PASSWORD_RESET_CONSULTANT_FAILURE,
  error,
});

const passwordChangeRequest = () => ({
  type: consultantConstants.PASSWORD_CHANGE_CONSULTANT_REQUEST,
});

const passwordChangeSuccess = (consultant) => ({
  type: consultantConstants.PASSWORD_CHANGE_CONSULTANT_SUCCESS,
  consultant,
});
const passwordChangeFailure = (error) => ({
  type: consultantConstants.PASSWORD_CHANGE_CONSULTANT_FAILURE,
  error,
});

const getConsultationTypeRequest = () => ({
  type: consultantConstants.GET_CONSULTATION_TYPE_REQUEST,
});

const getConsultationTypeSuccess = (consultationType) => {
  return {
    type: consultantConstants.GET_CONSULTATION_TYPE_SUCCESS,
    consultationTypes: consultationType,
  };
};

const getConsultationTypeFailure = (error) => ({
  type: consultantConstants.GET_CONSULTATION_TYPE_FAILURE,
  error,
});

const getPatientTypeRequest = () => ({
  type: consultantConstants.GET_PATIENT_TYPE_REQUEST,
});

const getPatientTypeSuccess = (patientTypes) => ({
  type: consultantConstants.GET_PATIENT_TYPE_SUCCESS,
  patientTypes,
});
const getPatientTypeFailure = (error) => ({
  type: consultantConstants.GET_PATIENT_TYPE_FAILURE,
  error,
});

const getMyActivityRequest = () => ({
  type: consultantConstants.GET_MY_ACTIVITY_REQUEST,
});

const getMyActivitySuccess = (activity) => ({
  type: consultantConstants.GET_MY_ACTIVITY_SUCCESS,
  activity,
});
const getMyActivityFailure = (error) => ({
  type: consultantConstants.GET_MY_ACTIVITY_FAILURE,
  error,
});

const clearForm = () => ({ type: consultantConstants.CLEAR_FORM });

export const consultantActions = {
  register,
  verify,
  login,
  logout,
  clearForm,
  resetPassword,
  changePassword,
  resendCode,
  getConsultationType,
  getPatientType,
  updateProfile,
  updateProfilePicture,
  getMyActivity,
};
