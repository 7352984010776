import { schedulesConstants } from 'redux/actions/types';
import moment from 'moment';

function setAppointmentsPerDay(day, days, appointments) {
  if (day && days.includes(day.format('MM-DD-YYYY'))) {
    const appointmentsPerDay = appointments.data.filter(
      (appointment) =>
        moment.unix(appointment.appointment_time).format('MM-DD-YYYY') ===
        day.format('MM-DD-YYYY')
    );
    return appointmentsPerDay;
  } else {
    return [];
  }
}

export function schedules(
  state = {
    loading: false,
    appointments: { data: [] },
    appointmentsPerDay: [],
    days: [],
  },
  action
) {
  switch (action.type) {
    case schedulesConstants.GET_SCHEDULES_REQUEST:
      return {
        ...state,
        requestSchedules: true,
      };
    case schedulesConstants.GET_SCHEDULES_SUCCESS:
      const data = action.schedules.data.map((sch) => ({
        ...sch,
        from_time: moment(sch.from_time, 'HH:mm:ss ZZ').format('X'),
        to_time: moment(sch.to_time, 'HH:mm:ss ZZ').format('X'),
      }));
      return {
        ...state,
        getSchedules: true,
        schedules: { data },
      };
    case schedulesConstants.GET_SCHEDULES_FAILURE:
      return {
        ...state,
        errors: { errors: action.error },
      };
    case schedulesConstants.GET_MY_BOOKED_APPOINTMENTS_REQUEST:
      return {
        ...state,
        requestAppointments: true,
        loading: true,
      };
    case schedulesConstants.GET_MY_BOOKED_APPOINTMENTS_SUCCESS:
      const days = action.appointments.data.map((appointment) =>
        moment.unix(appointment.appointment_time).format('MM-DD-YYYY')
      );

      return {
        ...state,
        getAppointments: true,
        appointments: action.appointments,
        loading: false,
        days: days,
        appointmentsPerDay: setAppointmentsPerDay(
          action.day,
          days,
          action.appointments
        ),
      };
    case schedulesConstants.GET_MY_BOOKED_APPOINTMENTS_FAILURE:
      return {
        ...state,
        errors: { errors: action.error },
        loading: false,
      };
    case schedulesConstants.ADD_SCHEDULES_REQUEST:
      return {
        ...state,
        addSchedules: true,
      };
    case schedulesConstants.ADD_SCHEDULES_SUCCESS:
      return {
        ...state,
        addedSchedules: true,
        schedules: action.schedules,
      };
    case schedulesConstants.ADD_SCHEDULES_FAILURE:
      return {
        ...state,
        errors: { errors: action.error },
      };
    case schedulesConstants.UPDATE_SCHEDULES_REQUEST:
      return {
        ...state,
        updateSchedules: true,
      };
    case schedulesConstants.UPDATE_SCHEDULES_SUCCESS:
      return {
        ...state,
        updatedSchedules: true,
        schedules: action.schedules,
      };
    case schedulesConstants.UPDATE_SCHEDULES_FAILURE:
      return {
        ...state,
        errors: { errors: action.error },
      };
    case schedulesConstants.GET_MY_BOOKED_APPOINTMENTS_PER_DAY:
      return {
        ...state,
        appointmentsPerDay: setAppointmentsPerDay(
          action.day,
          state.days,
          state.appointments
        ),
      };
    case schedulesConstants.CLEAR_DATA:
      return {
        loading: false,
        appointments: { data: [] },
        appointmentsPerDay: [],
        days: [],
      };
    default:
      return state;
  }
}
