const getSharedAllergies = (id, informationShareToken = null, page) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  let prams = null;
  if (informationShareToken && !page) {
    prams = `?informationShareToken=${informationShareToken}`;
  } else if (informationShareToken && page) {
    prams = `?informationShareToken=${informationShareToken}&page=${page}`;
  } else if (!informationShareToken && page) {
    prams = `?page=${page}`;
  }
  return fetch(`/patient/${id}/allergies${prams ? prams : ''}`, requestOptions)
    .then((allergies) => {
      return { error: false, allergies };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getAllergy = (id, patientId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient/${patientId}/allergies/show/${id}`, requestOptions)
    .then((allergy) => {
      return { error: false, allergy };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getCondition = (id, patientId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient/${patientId}/conditions/show/${id}`, requestOptions)
    .then((condition) => {
      return { error: false, condition };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getSharedConditions = (id, informationShareToken = null, page) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  let prams = null;
  if (informationShareToken && !page) {
    prams = `?informationShareToken=${informationShareToken}`;
  } else if (informationShareToken && page) {
    prams = `?informationShareToken=${informationShareToken}&page=${page}`;
  } else if (!informationShareToken && page) {
    prams = `?page=${page}`;
  }
  return fetch(`/patient/${id}/conditions${prams ? prams : ''}`, requestOptions)
    .then((conditions) => {
      return { error: false, conditions };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getECG = (id, patientId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient/${patientId}/ecgs/show/${id}`, requestOptions)
    .then((ecg) => {
      return { error: false, ecg };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getSharedECGs = (id, informationShareToken = null, page) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  let prams = null;
  if (informationShareToken && !page) {
    prams = `?informationShareToken=${informationShareToken}`;
  } else if (informationShareToken && page) {
    prams = `?informationShareToken=${informationShareToken}&page=${page}`;
  } else if (!informationShareToken && page) {
    prams = `?page=${page}`;
  }

  return fetch(`/patient/${id}/ecgs${prams ? prams : ''}`, requestOptions)
    .then((ecgs) => {
      return { error: false, ecgs };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getLabResult = (id, patientId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient/${patientId}/lab-results/show/${id}`, requestOptions)
    .then((labResult) => {
      return { error: false, labResult };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getSharedLabResults = (id, informationShareToken = null, page) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  let prams = null;
  if (informationShareToken && !page) {
    prams = `?informationShareToken=${informationShareToken}`;
  } else if (informationShareToken && page) {
    prams = `?informationShareToken=${informationShareToken}&page=${page}`;
  } else if (!informationShareToken && page) {
    prams = `?page=${page}`;
  }

  return fetch(
    `/patient/${id}/lab-results${prams ? prams : ''}`,
    requestOptions
  )
    .then((labResults) => {
      return { error: false, labResults };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getMedicalReport = (id, patientId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(
    `/patient/${patientId}/medical-reports/show/${id}`,
    requestOptions
  )
    .then((medicalReport) => {
      return { error: false, medicalReport };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getSharedMedicalReports = (id, informationShareToken = null, page) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  let prams = null;
  if (informationShareToken && !page) {
    prams = `?informationShareToken=${informationShareToken}`;
  } else if (informationShareToken && page) {
    prams = `?informationShareToken=${informationShareToken}&page=${page}`;
  } else if (!informationShareToken && page) {
    prams = `?page=${page}`;
  }
  return fetch(
    `/patient/${id}/medical-reports${prams ? prams : ''}`,
    requestOptions
  )
    .then((medicalReports) => {
      return { error: false, medicalReports };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getMRI = (id, patientId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient/${patientId}/mris/show/${id}/`, requestOptions)
    .then((mri) => {
      return { error: false, mri };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getSharedMRIs = (id, informationShareToken = null, page) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  let prams = null;
  if (informationShareToken && !page) {
    prams = `?informationShareToken=${informationShareToken}`;
  } else if (informationShareToken && page) {
    prams = `?informationShareToken=${informationShareToken}&page=${page}`;
  } else if (!informationShareToken && page) {
    prams = `?page=${page}`;
  }

  return fetch(`/patient/${id}/mris${prams ? prams : ''}`, requestOptions)
    .then((mris) => {
      return { error: false, mris };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getXRay = (id, patientId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient/${patientId}/xrays/show/${id}/`, requestOptions)
    .then((xray) => {
      return { error: false, xray };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getSharedXRays = (id, informationShareToken = null, page) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  let prams = null;
  if (informationShareToken && !page) {
    prams = `?informationShareToken=${informationShareToken}`;
  } else if (informationShareToken && page) {
    prams = `?informationShareToken=${informationShareToken}&page=${page}`;
  } else if (!informationShareToken && page) {
    prams = `?page=${page}`;
  }
  return fetch(`/patient/${id}/xrays${prams ? prams : ''}`, requestOptions)
    .then((xrays) => {
      return { error: false, xrays };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};
const getCT = (id, patientId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient/${patientId}/cts/show/${id}/`, requestOptions)
    .then((ct) => {
      return { error: false, ct };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};
const getSharedCTs = (id, informationShareToken = null, page) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  let prams = null;
  if (informationShareToken && !page) {
    prams = `?informationShareToken=${informationShareToken}`;
  } else if (informationShareToken && page) {
    prams = `?informationShareToken=${informationShareToken}&page=${page}`;
  } else if (!informationShareToken && page) {
    prams = `?page=${page}`;
  }

  return fetch(`/patient/${id}/cts${prams ? prams : ''}`, requestOptions)
    .then((cts) => {
      return { error: false, cts };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getRadiology = (id, patientId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient/${patientId}/radiology/show/${id}/`, requestOptions)
    .then((radiology) => {
      return { error: false, radiology };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};
const getSharedRadiologist = (id, informationShareToken = null, page) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  let prams = null;
  if (informationShareToken && !page) {
    prams = `?informationShareToken=${informationShareToken}`;
  } else if (informationShareToken && page) {
    prams = `?informationShareToken=${informationShareToken}&page=${page}`;
  } else if (!informationShareToken && page) {
    prams = `?page=${page}`;
  }

  return fetch(`/patient/${id}/radiology${prams ? prams : ''}`, requestOptions)
    .then((radiologist) => {
      return { error: false, radiologist };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const patientReportsServices = {
  getSharedAllergies,
  getAllergy,
  getCondition,
  getSharedConditions,
  getCT,
  getSharedCTs,
  getSharedECGs,
  getECG,
  getLabResult,
  getSharedLabResults,
  getMedicalReport,
  getSharedMedicalReports,
  getSharedMRIs,
  getMRI,
  getXRay,
  getSharedXRays,
  getRadiology,
  getSharedRadiologist,
};

export default patientReportsServices;
