import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          // user.mobileVerifiedAt === null && user.facebookId === null ? (
          //   <Redirect
          //     to={{
          //       pathname: `/verify-mobile/${user.mobile}`,
          //       state: { from: props.location },
          //     }}
          //   />
          // ) : (
          //   <Component {...props} />
          // )
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  location: PropTypes.object,
};
