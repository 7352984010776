import React, { Component } from 'react';
import { Row, Col } from 'antd';
import styled from '@emotion/styled/macro';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { consultationActions, consultantActions } from 'redux/actions';
import { SideBar, NewConsultants, ActiveConsultants } from './components';
import {
  MainLayout,
  Container,
  AcceptConsultants,
  CancelConsultants,
} from '@mous221/hk-components';
import { isMobile } from 'react-device-detect';
import { Modal } from '@mous221/hk-utils';
import { device } from 'utils';
const HomeWrapper = styled.div`
  margin-top: 60px;
  .home-content,
  .home-sidebar {
    padding: 0 15px;
  }
`;
const Wrapper = styled.div`
  .modal__content {
    @media ${device.allMobile} {
      width: 80%;
    }
  }
`;
class HomePage extends Component {
  state = {
    showModal: false,
    modalTitle: '',
    modalImage: '',
    whichModalShow: '',
    acceptedConsultantsid: null,
    cancelConsultantsid: null,
    notVerified: null,
  };
  componentDidMount() {
    const {
      getNewConsultation,
      getAcceptedConsultation,
      getMyActivity,
    } = this.props;
    const user = JSON.parse(localStorage.getItem('user'));
    if (user.verifiedAt) {
      getAcceptedConsultation();
      getNewConsultation();
      getMyActivity();
    } else {
      this.setState({ notVerified: true });
    }
  }

  handelShowModal = (whichItem) => {
    this.setState({ showModal: true, whichModalShow: whichItem });
  };

  handelCloseModal = () => {
    this.setState({ showModal: false });
  };
  setAcceptedConsultantsid = (id) => {
    this.setState({ acceptedConsultantsid: id });
  };
  setCancelConsultantsid = (id) => {
    this.setState({ cancelConsultantsid: id });
  };

  render() {
    const {
      showModal,
      modalTitle,
      modalImage,
      whichModalShow,
      acceptedConsultantsid,
      cancelConsultantsid,
      notVerified,
    } = this.state;

    const {
      newConsultations,
      acceptConsultation,
      rejectConsultation,
      acceptedConsultations,
      activity,
    } = this.props;

    return (
      <Wrapper>
        <MainLayout footer="main" {...this.props}>
          <Container>
            <HomeWrapper>
              {!notVerified ? (
                <Row>
                  <Col span={isMobile ? 24 : 16} className="home-content">
                    {newConsultations && (
                      <NewConsultants
                        handelShowModal={this.handelShowModal}
                        newConsultations={newConsultations}
                        acceptConsultation={acceptConsultation}
                        rejectConsultation={rejectConsultation}
                        setAcceptedConsultantsid={this.setAcceptedConsultantsid}
                        setCancelConsultantsid={this.setCancelConsultantsid}
                      />
                    )}
                    {acceptedConsultations && (
                      <ActiveConsultants
                        acceptedConsultations={acceptedConsultations}
                      />
                    )}
                  </Col>
                  {!isMobile && (
                    <Col span={isMobile ? 24 : 8} className="home-sidebar">
                      <SideBar activity={activity} />
                    </Col>
                  )}
                </Row>
              ) : (
                <h1>
                  Your account is inactive contact administrator to activate it
                </h1>
              )}
            </HomeWrapper>
          </Container>
        </MainLayout>
        {whichModalShow && (
          <Modal
            title={modalTitle}
            image={modalImage}
            showModal={showModal}
            handelCloseModal={this.handelCloseModal}
          >
            <>
              {whichModalShow === 'Accept' && (
                <AcceptConsultants consultantsId={acceptedConsultantsid} />
              )}
              {whichModalShow === 'Cancel' && (
                <CancelConsultants consultantsId={cancelConsultantsid} />
              )}
            </>
          </Modal>
        )}
      </Wrapper>
    );
  }
}
const mapStateToProps = (state) => {
  const { newConsultations, acceptedConsultations } = state.consultation;
  const { activity } = state.consultantAuth;

  return {
    newConsultations,
    acceptedConsultations,
    activity,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getNewConsultation: () => dispatch(consultationActions.getNewConsultation()),
  getAcceptedConsultation: () =>
    dispatch(consultationActions.getAcceptedConsultation()),
  getMyActivity: () => dispatch(consultantActions.getMyActivity()),
  acceptConsultation: (id) =>
    dispatch(consultationActions.acceptConsultation(id)),
  rejectConsultation: (id) =>
    dispatch(consultationActions.rejectConsultation(id)),
  logout: () => dispatch(consultantActions.logout()),
});

const WrappedHomePage = connect(mapStateToProps, mapDispatchToProps)(HomePage);
export default WrappedHomePage;

HomePage.propTypes = {
  newConsultations: PropTypes.object,
  acceptedConsultations: PropTypes.object,
  activity: PropTypes.object,
  acceptConsultation: PropTypes.func.isRequired,
  getAcceptedConsultation: PropTypes.func.isRequired,
  rejectConsultation: PropTypes.func.isRequired,
  getNewConsultation: PropTypes.func.isRequired,
  getMyActivity: PropTypes.func.isRequired,
};
