import React from 'react';
import { connect } from 'react-redux';
import { consultantActions } from 'redux/actions';
import { MainLayout } from '@mous221/hk-components';
import ECGsComponents from './ECGs';

function ECGsPage(props) {
  return (
    <MainLayout
      footer="main"
      children={<ECGsComponents {...props} />}
      {...props}
    />
  );
}
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(consultantActions.logout()),
});

export default connect(() => ({}), mapDispatchToProps)(ECGsPage);
