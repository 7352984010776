import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { convertDateToFullStringDate, H1, Modal } from '@mous221/hk-utils';
import { consultationActions } from 'redux/actions';
import styled from '@emotion/styled';

import { PlusIcon, QuestionIcon } from 'images';
import { AskQuestionForm } from '../';

const ViewQuestionsWrapper = styled.div`
  .bread-crumbs {
    p {
      font-size: 14px;
      font-weight: bold;
      color: #b4b4b4;
      cursor: pointer;
    }
  }
  .request {
    display: block;
    margin: 15px 0;
    color: #97d7d8;
    border: none;
    background: none;
    cursor: pointer;
    img {
      margin-right: 10px;
      width: 25px;
      height: 25px;
      margin-top: 20px;
      position: relative;
      top: -11px;
    }
  }
`;
const QuestionsWrapper = styled.div`
  margin: 25px 0;
`;
const QuestionWrapper = styled.div`
  display: flex;
  padding: 16px;
  padding-left: 0;
  background: #fff;
  border-radius: 7.2px;
  box-shadow: 1px 1px 2px 1px rgba(3, 3, 4, 0);
  margin-bottom: 15px;
  & > div {
    min-width: 90px;
    &:first-of-type {
      text-align: center;
      img {
        width: 60px;
        height: 60px;
      }
    }
    h2 {
      font-size: 20px;
      font-weight: bold;
      margin: 0;
      & + p {
        font-size: 16px;
        font-weight: normal;
        color: #9298ac;
        margin-bottom: 18px;
      }
    }
    p {
      max-width: 615px;
      font-size: 16px;
      font-weight: normal;
      line-height: 2;
      letter-spacing: normal;
      color: #060c2b;
    }
  }
  .question,
  .reply {
    h3 {
      font-weight: bold;
      font-size: 18px;
      margin: 0;
    }
    p {
      font-size: 16px;
      margin: 0;
    }
  }
`;
export class ViewQuestions extends Component {
  static propTypes = {
    getQuestions: PropTypes.func.isRequired,
    handleCloseQuestion: PropTypes.func.isRequired,
    requestQuestion: PropTypes.func.isRequired,
    consultationId: PropTypes.number.isRequired,
    questions: PropTypes.array.isRequired,
  };
  componentDidMount() {
    const { getQuestions, consultationId } = this.props;
    getQuestions(consultationId);
  }
  state = {
    showModal: false,
  };

  handelCloseModal = () => {
    this.setState({ showModal: false });
  };

  handelShowModal = () => {
    this.setState({ showModal: true });
  };
  render() {
    const {
      handleCloseQuestion,
      requestQuestion,
      consultationId,
      questions,
    } = this.props;
    const { showModal } = this.state;

    return (
      <>
        <ViewQuestionsWrapper>
          <div className="bread-crumbs">
            <p onClick={() => handleCloseQuestion()}>Back to Consultation</p>
          </div>
          <H1>General questions</H1>
          <div>
            <button className="request" onClick={() => this.handelShowModal()}>
              <img src={PlusIcon} alt="Plus" />
              Ask question
            </button>
          </div>
          <QuestionsWrapper>
            {questions.map((question) => (
              <QuestionWrapper key={JSON.stringify(question)}>
                <div>
                  <img src={QuestionIcon} alt="Question" />
                </div>
                <div>
                  <div>
                    <h2>{question.title}</h2>
                    <p>{convertDateToFullStringDate(question.updatedAt)}</p>
                  </div>
                  <div className="question">
                    <h3>Question:</h3>
                    <p>{question.body}</p>
                  </div>
                  {question.reply && (
                    <div className="reply">
                      <h3>Reply:</h3>
                      <p>{question.reply}</p>
                    </div>
                  )}
                </div>
              </QuestionWrapper>
            ))}
          </QuestionsWrapper>
        </ViewQuestionsWrapper>
        <Modal
          title={'Ask Question'}
          image={QuestionIcon}
          showModal={showModal}
          handelCloseModal={this.handelCloseModal}
        >
          <>
            <AskQuestionForm
              requestQuestion={requestQuestion}
              consultationId={Number(consultationId)}
              handelCloseModal={this.handelCloseModal}
            />
          </>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { consultation, patientActivity, questions } = state.consultation;

  return {
    consultation,
    patientActivity,
    questions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  requestQuestion: ({ title, body }, consultationId) =>
    dispatch(
      consultationActions.requestQuestion({ title, body }, consultationId)
    ),
  getQuestions: (consultationId) =>
    dispatch(consultationActions.getQuestions(consultationId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ViewQuestions);
