import React, { Component } from 'react';
import styled from '@emotion/styled/macro';
import { Form, Input } from 'antd';
import 'antd/lib/form/style/css';
import PropTypes from 'prop-types';

import { FormWrapper } from '@mous221/hk-components';
import { Colors } from '@mous221/hk-utils';
import { history } from 'utils';
const FormItem = Form.Item;

const SubmitButton = styled.button`
  width: 100%;
  background: ${Colors.black};
  color: ${Colors.white};
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px ${Colors.gary};
  font-family: Heebo;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
  padding: 10px 0;
  @media (max-width: 767px) {
    margin-top: 15px;
    margin-bottom: 0;
  }
`;

const RequestMedicalRecordFormWrapper = styled.div`
  .text-area {
    margin-top: 15px;
    min-height: 150px;
  }
`;

class SendReportForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      form: { validateFields },
      consultationId,
      sendReport,
      setViewEditor,
    } = this.props;

    validateFields((err, values) => {
      if (!err) {
        sendReport({
          title: values.title,
          note: values.notes,
          consultationId,
        });
        setViewEditor(false);
      }
      history.push('/');
    });
  };
  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;

    return (
      <FormWrapper width={650}>
        <RequestMedicalRecordFormWrapper>
          <Form onSubmit={this.handleSubmit}>
            <FormItem>
              {getFieldDecorator('title', {
                rules: [
                  {
                    required: true,
                    message: 'please fill Title field',
                  },
                ],
              })(<Input placeholder="Title" />)}
            </FormItem>
            <FormItem>
              {getFieldDecorator('notes', {
                rules: [],
              })(<Input.TextArea placeholder="Notes" className="text-area" />)}
            </FormItem>
            <FormItem>
              <SubmitButton className="submit-button" htmlType="submit">
                Send
              </SubmitButton>
            </FormItem>
          </Form>
        </RequestMedicalRecordFormWrapper>
      </FormWrapper>
    );
  }
}

export default Form.create({})(SendReportForm);
SendReportForm.propTypes = {
  form: PropTypes.object.isRequired,
  consultationId: PropTypes.number.isRequired,
  sendReport: PropTypes.func.isRequired,
  setViewEditor: PropTypes.func.isRequired,
};
