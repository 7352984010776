import moment from 'moment';
import { dayValue } from 'utils';

const HandleAppointmentDate = (time, day) => {
  const weekStart = time.clone().startOf('isoWeek');
  const daysStart = moment(weekStart).add(dayValue(day), 'days');

  daysStart.set({
    hour: time.get('hour'),
    minute: time.get('minute'),
    second: 0,
  });

  return daysStart.format('HH:mm:ss ZZ');
};

export default HandleAppointmentDate;
