import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReadMoreAndLess from 'react-read-more-less';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

import {
  ProfilePic,
  convertDateToFullStringDate,
  CalculateTimes,
} from '@mous221/hk-utils';
import { CloseIcon, CheckIcon } from 'images';
import { isMobile } from 'react-device-detect';
import { device } from 'utils';
const ActiveConsultantWrapper = styled.div`
  margin-bottom: 15px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  border-radius: 6px;
`;
const ActiveConsultantHeader = styled.header`
  background-color: #f9fafc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 6px 6px 0 0;
`;
const ImageWrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
  p {
    margin-bottom: 0;
    line-height: 24px;
    vertical-align: middle;
    margin-left: 10px;
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
  }
`;
const ConsultantTime = styled.div`
  p {
    margin-bottom: 0;
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
  }
`;
const ActiveConsultantContent = styled.div`
  background-color: #fff;
  padding: 16px;
  font-family: AvenirNext, Arial, sans-serif;
  .active-consultant {
    &__time {
      font-size: 14px;
      color: #9298ac;
      margin-bottom: 15px;
    }
    &__title {
      font-size: 18px;
      font-weight: bold;
      color: #060c2b;
    }
  }
  .short-text {
    font-size: 16px;
    font-weight: 500;
    color: #9298ac;
    margin-bottom: 0;
  }
  .readMoreText {
    color: #97d7d8 !important;
  }
`;
const ActiveConsultantFotter = styled.footer`
  background-color: #fff;
  border-top: 1px solid #e4e9f2;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  border-radius: 0 0 6px 6px;
  a {
    font-size: 16px;
    font-weight: bold;
    color: #97d7d8;
  }
  p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500;
    color: #97d7d8;
    text-transform: capitalize;
  }
`;
const ConsultantActions = styled.div`
  display: flex;
  @media ${device.allMobile} {
    width: 100%;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  div {
    a {
      display: flex;
      align-items: center;
      color: #97d7d8;
      font-size: 15px;
      span {
        margin-left: 5px;
        line-height: 1;
      }
    }
    & + div {
      margin-left: 15px;
      a {
        color: #ec0707;
      }
    }
  }
`;
export default class ActiveConsultant extends Component {
  render() {
    const {
      notAnswerYet,
      handelShowModal,
      consultation,
      rejectConsultation,
      setCancelConsultantsid,
    } = this.props;
    const startDate = convertDateToFullStringDate(consultation.createdAt);

    return (
      <ActiveConsultantWrapper>
        <ActiveConsultantHeader>
          <ImageWrapper>
            <ProfilePic user={consultation.patient} />
            <p>{consultation.patient.fullName}</p>
          </ImageWrapper>
          {notAnswerYet ? (
            <>
              {!isMobile && (
                <ConsultantActions>
                  <div>
                    <Link to={`/consultation/${consultation.id}`}>
                      <CheckIcon />
                      <span>View</span>
                    </Link>
                  </div>
                  <div>
                    <Link
                      to="#!"
                      onClick={() => {
                        rejectConsultation(consultation.id);
                        handelShowModal('Cancel');
                        setCancelConsultantsid(consultation.id);
                      }}
                    >
                      <CloseIcon /> <span>Reject</span>
                    </Link>
                  </div>
                </ConsultantActions>
              )}
            </>
          ) : (
            <ConsultantTime>
              <p>
                {consultation.inactiveConsultantAt &&
                  CalculateTimes(consultation.inactiveConsultantAt)}
              </p>
            </ConsultantTime>
          )}
        </ActiveConsultantHeader>
        <ActiveConsultantContent>
          {notAnswerYet && isMobile && (
            <ConsultantActions>
              <div>
                <Link to={`/consultation/${consultation.id}`}>
                  <CheckIcon />
                  <span>View</span>
                </Link>
              </div>
              <div>
                <Link
                  to="#!"
                  onClick={() => {
                    rejectConsultation(consultation.id);
                    handelShowModal('Cancel');
                    setCancelConsultantsid(consultation.id);
                  }}
                >
                  <CloseIcon /> <span>Reject</span>
                </Link>
              </div>
            </ConsultantActions>
          )}
          <p className="active-consultant__time">Starting on: {startDate}</p>

          <h3 className="active-consultant__title">
            {consultation && consultation.symptoms}
          </h3>
          <ReadMoreAndLess
            ref={this.ReadMore}
            charLimit={110}
            readMoreText="More"
            readLessText=" Less"
          >
            {consultation && consultation.symptomsExplained
              ? consultation.symptomsExplained
              : 'there is no text'}
          </ReadMoreAndLess>
        </ActiveConsultantContent>
        {!notAnswerYet && (
          <ActiveConsultantFotter>
            <Link to={`/consultation/${consultation.id}`}>
              Add Consultation
            </Link>
            <p>{consultation.status}</p>
          </ActiveConsultantFotter>
        )}
      </ActiveConsultantWrapper>
    );
  }
}

ActiveConsultant.propTypes = {
  notAnswerYet: PropTypes.bool,
  handelShowModal: PropTypes.func,
  consultation: PropTypes.object.isRequired,
  rejectConsultation: PropTypes.func,
  setCancelConsultantsid: PropTypes.func,
};
