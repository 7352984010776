import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { AppointmentTimeForm } from '@mous221/hk-components';

export class Schedules extends React.Component {
  handleCancel = (e) => {
    e.preventDefault();

    const {
      form: { setFields },
      // schedules,
      handleFormToggle,
    } = this.props;

    setFields({});
    handleFormToggle();
  };

  render() {
    const { form, editFormIsOpen, handleFormToggle, schedules } = this.props;

    const twoWeeksAgo = moment().clone().subtract(7, 'days').startOf('day');
    const updateFromMoreThanTwoWeek =
      schedules &&
      schedules.length > 0 &&
      moment.unix(schedules[0].updated_at).isBefore(twoWeeksAgo);
    console.log('schedules', schedules);
    return (
      <div>
        <AppointmentTimeForm
          schedules={schedules}
          form={form}
          editFormIsOpen={editFormIsOpen}
          handleEditFormToggle={handleFormToggle}
          handleCancel={this.handleCancel}
          updateFromMoreThanTwoWeek={updateFromMoreThanTwoWeek}
        />
      </div>
    );
  }
}

export default Schedules;

Schedules.propTypes = {
  schedules: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  editFormIsOpen: PropTypes.bool.isRequired,
  handleFormToggle: PropTypes.func.isRequired,
};
