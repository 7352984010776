import React, { Component } from 'react';
import styled from '@emotion/styled';
import { Form, Input } from 'antd';
import 'antd/lib/form/style/css';
import PropTypes from 'prop-types';

import { FormWrapper } from '@mous221/hk-components';
import { Colors } from '@mous221/hk-utils';

const FormItem = Form.Item;

const SubmitButton = styled.button`
  width: 100%;
  background: ${Colors.black};
  color: ${Colors.white};
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px ${Colors.gary};
  font-family: Heebo;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
  padding: 10px 0;
  @media (max-width: 767px) {
    margin-top: 15px;
    margin-bottom: 0;
  }
`;

const RequestMedicalRecordFormWrapper = styled.div`
  .text-area {
    margin-top: 15px;
    height: 150px;
    margin-bottom: 15px;
  }
  .Form-wrapper {
    border: none;
  }
  .ant-select {
    margin-bottom: 15px;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

class RequestMedicalRecordForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      form: { validateFields },
      requestQuestion,
      consultationId,
      handelCloseModal,
    } = this.props;

    validateFields((err, values) => {
      if (!err) {
        requestQuestion(values, consultationId);
        handelCloseModal();
      }
    });
  };
  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;

    return (
      <RequestMedicalRecordFormWrapper>
        <FormWrapper>
          <Form onSubmit={this.handleSubmit}>
            <FormItem>
              {getFieldDecorator('title', {
                rules: [
                  {
                    required: true,
                    message: 'please fill request title field',
                  },
                ],
              })(<Input placeholder="Add request title" />)}
            </FormItem>
            <FormItem>
              {getFieldDecorator('body', {
                rules: [
                  {
                    required: true,
                    message: 'please fill Notes field',
                  },
                ],
              })(<Input.TextArea placeholder="Notes" className="text-area" />)}
            </FormItem>

            <FormItem>
              <SubmitButton className="submit-button" htmlType="submit">
                Ask
              </SubmitButton>
            </FormItem>
          </Form>
        </FormWrapper>
      </RequestMedicalRecordFormWrapper>
    );
  }
}

export default Form.create({})(RequestMedicalRecordForm);

RequestMedicalRecordForm.propTypes = {
  form: PropTypes.object.isRequired,
  requestQuestion: PropTypes.func.isRequired,
  consultationId: PropTypes.number.isRequired,
  handelCloseModal: PropTypes.func.isRequired,
};
