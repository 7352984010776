import { alertActions } from 'redux/actions';
import { schedulesServices } from 'services';
import schedulesConstants from './types';

const getSchedules = () => {
  return (dispatch) => {
    dispatch(getSchedulesRequest());

    schedulesServices.getSchedules().then((res) => {
      if (res.error) {
        dispatch(getSchedulesFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(getSchedulesSuccess(res.schedules));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getMyBookedAppointments = (month, day = null) => {
  return (dispatch) => {
    dispatch(getMyBookedAppointmentsRequest());

    schedulesServices.getMyBookedAppointments(month).then((res) => {
      if (res.error) {
        dispatch(getMyBookedAppointmentsFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(getMyBookedAppointmentsSuccess(res.appointments, day));
        dispatch(alertActions.clear());
      }
    });
  };
};
const addSchedules = (schedules) => {
  return (dispatch) => {
    dispatch(addSchedulesRequest());

    schedulesServices.addSchedules(schedules).then((res) => {
      if (res.error) {
        dispatch(addSchedulesFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(addSchedulesSuccess(res.schedules));
        dispatch(alertActions.clear());
      }
    });
  };
};

const updateSchedules = (schedules) => {
  return (dispatch) => {
    dispatch(updateSchedulesRequest());

    schedulesServices.updateSchedules(schedules).then((res) => {
      if (res.error) {
        dispatch(updateSchedulesFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(updateSchedulesSuccess(res.schedule));
        dispatch(alertActions.success('Your Schedules has been updated'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getAppointmentsPerDay = (day) => {
  return (dispatch) => {
    dispatch(getAppointmentsPerDaySuccess(day));
  };
};

const getAppointmentsPerDaySuccess = (day) => ({
  type: schedulesConstants.GET_MY_BOOKED_APPOINTMENTS_PER_DAY,
  day,
});
const getSchedulesRequest = () => ({
  type: schedulesConstants.GET_SCHEDULES_REQUEST,
});

const getSchedulesSuccess = (schedules) => {
  return {
    type: schedulesConstants.GET_SCHEDULES_SUCCESS,
    schedules: schedules,
  };
};

const getSchedulesFailure = (error) => ({
  type: schedulesConstants.GET_SCHEDULES_FAILURE,
  error,
});

const getMyBookedAppointmentsRequest = () => ({
  type: schedulesConstants.GET_MY_BOOKED_APPOINTMENTS_REQUEST,
});

const getMyBookedAppointmentsSuccess = (appointments, day) => {
  return {
    type: schedulesConstants.GET_MY_BOOKED_APPOINTMENTS_SUCCESS,
    appointments,
    day,
  };
};

const getMyBookedAppointmentsFailure = (error) => ({
  type: schedulesConstants.GET_MY_BOOKED_APPOINTMENTS_FAILURE,
  error,
});
const addSchedulesRequest = () => ({
  type: schedulesConstants.ADD_SCHEDULES_REQUEST,
});

const addSchedulesSuccess = (schedules) => {
  return {
    type: schedulesConstants.ADD_SCHEDULES_SUCCESS,
    schedules: schedules,
  };
};

const addSchedulesFailure = (error) => ({
  type: schedulesConstants.ADD_SCHEDULES_FAILURE,
  error,
});

const updateSchedulesRequest = () => ({
  type: schedulesConstants.UPDATE_SCHEDULES_REQUEST,
});

const updateSchedulesSuccess = (schedules) => {
  return {
    type: schedulesConstants.UPDATE_SCHEDULES_SUCCESS,
    schedules: schedules,
  };
};

const updateSchedulesFailure = (error) => ({
  type: schedulesConstants.UPDATE_SCHEDULES_FAILURE,
  error,
});
const clearData = () => ({
  type: schedulesConstants.CLEAR_DATA,
});
export const schedulesActions = {
  getSchedules,
  getMyBookedAppointments,
  addSchedules,
  updateSchedules,
  getAppointmentsPerDay,
  clearData,
};
