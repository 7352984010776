import { alertActions } from 'redux/actions';
import { consultationService } from 'services';
import consultationConstants from './types';

const getNewConsultation = () => {
  return (dispatch) => {
    dispatch(getNewConsultationRequest());

    consultationService.getNewConsultation().then((res) => {
      if (res.error) {
        dispatch(getNewConsultationFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(getNewConsultationSuccess(res.consultations));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getPatientActivity = (id) => {
  return (dispatch) => {
    dispatch(getPatientActivityRequest());

    consultationService.getPatientActivity(id).then((res) => {
      if (res.error) {
        dispatch(getPatientActivityFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(getPatientActivitySuccess(res.activity));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getAcceptedConsultation = () => {
  return (dispatch) => {
    dispatch(getAcceptedConsultationRequest());

    consultationService.getAcceptedConsultation().then((res) => {
      if (res.error) {
        dispatch(getAcceptedConsultationFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(getAcceptedConsultationSuccess(res.consultations));
        dispatch(alertActions.clear());
      }
    });
  };
};

const acceptConsultation = (id) => {
  return (dispatch) => {
    dispatch(acceptConsultationRequest());

    consultationService.acceptConsultation(id).then((res) => {
      if (res.error) {
        dispatch(acceptConsultationFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
      } else {
        dispatch(acceptConsultationSuccess(res.consultation));
        dispatch(alertActions.success('You have accepted this consultation'));
      }
      dispatch(alertActions.clear());
    });
  };
};

const rejectConsultation = (id) => {
  return (dispatch) => {
    dispatch(rejectConsultationRequest());

    consultationService.rejectConsultation(id).then((res) => {
      if (res.error) {
        dispatch(rejectConsultationFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
      } else {
        dispatch(rejectConsultationSuccess(res.consultation, id));
        dispatch(alertActions.success('Your Just Reject The Consultation'));
      }
      dispatch(alertActions.clear());
    });
  };
};

const getConsultation = (id) => {
  return (dispatch) => {
    dispatch(getConsultationRequest());

    consultationService.getConsultation(id).then((res) => {
      if (res.error) {
        dispatch(getConsultationFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(getPatientActivity(res.consultation.patientId));
        dispatch(getConsultationSuccess(res.consultation));
        dispatch(alertActions.clear());
      }
    });
  };
};

const requestScan = ({ scanType, id, title, body }) => {
  return (dispatch) => {
    dispatch(requestScanRequest());

    consultationService
      .requestScan({ scanType, id, title, body })
      .then((res) => {
        if (res.error) {
          dispatch(requestScanFailure(res.errors));
          res.errors.map((err) => dispatch(alertActions.error(err)));
          dispatch(alertActions.clear());
        } else {
          dispatch(requestScanSuccess(res.scan));
          dispatch(
            alertActions.success(
              `The ${scanType
                .replaceAll('_', ' ')
                .replaceAll('-', ' ')} has been sent`
            )
          );
          dispatch(alertActions.clear());
        }
      });
  };
};

const sendReport = ({ title, note, consultationId }) => {
  return (dispatch) => {
    dispatch(sendReportRequest());

    consultationService
      .sendReport({ title, note, consultationId })
      .then((res) => {
        if (res.error) {
          dispatch(sendReportFailure(res.errors));
          res.errors.map((err) => dispatch(alertActions.error(err)));
          dispatch(alertActions.clear());
        } else {
          dispatch(sendReportSuccess(consultationId));
          dispatch(alertActions.success(`Your report has been sent`));
          dispatch(alertActions.clear());
        }
      });
  };
};

const getFinishedConsultations = (page, name, date) => {
  return (dispatch) => {
    dispatch(getFinishedConsultationsRequest());

    consultationService
      .getFinishedConsultations(page, name, date)
      .then((res) => {
        if (res.error) {
          dispatch(getFinishedConsultationsFailure(res.errors));
          res.errors.map((err) => dispatch(alertActions.error(err)));
          dispatch(alertActions.clear());
        } else {
          dispatch(getFinishedConsultationsSuccess(res.FinishedConsultations));
          dispatch(alertActions.clear());
        }
      });
  };
};

const requestQuestion = (question, consultationId) => {
  return (dispatch) => {
    dispatch(requestQuestionRequest());

    consultationService
      .requestQuestion(question, consultationId)
      .then((res) => {
        if (res.error) {
          dispatch(requestQuestionFailure(res.errors));
          res.errors.map((err) => dispatch(alertActions.error(err)));
          dispatch(alertActions.clear());
        } else {
          dispatch(requestQuestionSuccess(res.question));
          dispatch(alertActions.success(`The question has send`));
          dispatch(alertActions.clear());
        }
      });
  };
};

const requestQuestionRequest = (question, consultationId) => ({
  type: consultationConstants.REQUEST_QUESTION_REQUEST,
  question,
  consultationId,
});

const requestQuestionSuccess = (question) => {
  return {
    type: consultationConstants.REQUEST_QUESTION_SUCCESS,
    question,
  };
};

const requestQuestionFailure = (error) => ({
  type: consultationConstants.REQUEST_QUESTION_FAILURE,
  error,
});

const getQuestions = (consultationId) => {
  return (dispatch) => {
    dispatch(getQuestionsRequest());

    consultationService.getQuestions(consultationId).then((res) => {
      if (res.error) {
        dispatch(getQuestionsFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(getQuestionsSuccess(res.questions.data));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getQuestionsRequest = (consultationId) => ({
  type: consultationConstants.GET_QUESTIONS_REQUEST,

  consultationId,
});

const getQuestionsSuccess = (questions) => {
  return {
    type: consultationConstants.GET_QUESTIONS_SUCCESS,
    questions,
  };
};

const getQuestionsFailure = (error) => ({
  type: consultationConstants.GET_QUESTIONS_FAILURE,
  error,
});

const getPatientActivityRequest = () => ({
  type: consultationConstants.GET_PATIENT_ACTIVITY_REQUEST,
});

const getPatientActivitySuccess = (activity) => {
  return {
    type: consultationConstants.GET_PATIENT_ACTIVITY_SUCCESS,
    activity,
  };
};

const getPatientActivityFailure = (error) => ({
  type: consultationConstants.GET_PATIENT_ACTIVITY_FAILURE,
  error,
});

const getNewConsultationRequest = () => ({
  type: consultationConstants.GET_CONSULTATIONS_REQUEST,
});

const getNewConsultationSuccess = (consultations) => {
  return {
    type: consultationConstants.GET_CONSULTATIONS_SUCCESS,
    consultations: consultations,
  };
};

const getNewConsultationFailure = (error) => ({
  type: consultationConstants.GET_CONSULTATIONS_FAILURE,
  error,
});

const getFinishedConsultationsRequest = () => ({
  type: consultationConstants.GET_FINISHED_CONSULTATIONS_REQUEST,
});

const getFinishedConsultationsSuccess = (FinishedConsultations) => {
  return {
    type: consultationConstants.GET_FINISHED_CONSULTATIONS_SUCCESS,
    FinishedConsultations,
  };
};

const getFinishedConsultationsFailure = (error) => ({
  type: consultationConstants.GET_FINISHED_CONSULTATIONS_FAILURE,
  error,
});

const getAcceptedConsultationRequest = () => ({
  type: consultationConstants.GET_ACCEPTED_CONSULTATION_REQUEST,
});

const getAcceptedConsultationSuccess = (consultations) => {
  return {
    type: consultationConstants.GET_ACCEPTED_CONSULTATION_SUCCESS,
    consultations: consultations,
  };
};

const getAcceptedConsultationFailure = (error) => ({
  type: consultationConstants.GET_ACCEPTED_CONSULTATION_FAILURE,
  error,
});

const acceptConsultationRequest = () => ({
  type: consultationConstants.ACCEPT_CONSULTATION_REQUEST,
});

const acceptConsultationSuccess = (consultation) => {
  return {
    type: consultationConstants.ACCEPT_CONSULTATION_SUCCESS,
    consultation: consultation,
  };
};

const acceptConsultationFailure = (error) => ({
  type: consultationConstants.ACCEPT_CONSULTATION_FAILURE,
  error,
});

const rejectConsultationRequest = () => ({
  type: consultationConstants.REJECT_CONSULTATION_REQUEST,
});

const rejectConsultationSuccess = (consultation, id) => {
  return {
    type: consultationConstants.REJECT_CONSULTATION_SUCCESS,
    consultation: consultation,
    consultationId: id,
  };
};

const rejectConsultationFailure = (error) => ({
  type: consultationConstants.REJECT_CONSULTATION_FAILURE,
  error,
});

const getConsultationRequest = () => ({
  type: consultationConstants.GET_CONSULTATION_REQUEST,
});

const getConsultationSuccess = (consultation) => {
  return {
    type: consultationConstants.GET_CONSULTATION_SUCCESS,
    consultation: consultation,
  };
};

const getConsultationFailure = (error) => ({
  type: consultationConstants.GET_CONSULTATION_FAILURE,
  error,
});

const requestScanRequest = () => ({
  type: consultationConstants.REQUEST_SCAN_REQUEST,
});

const requestScanSuccess = (scan) => {
  return {
    type: consultationConstants.REQUEST_SCAN_SUCCESS,
    scan: scan,
  };
};

const requestScanFailure = (error) => ({
  type: consultationConstants.REQUEST_SCAN_FAILURE,
  error,
});

const sendReportRequest = () => ({
  type: consultationConstants.SEND_REPORT_REQUEST,
});

const sendReportSuccess = (consultationId) => {
  return {
    type: consultationConstants.SEND_REPORT_SUCCESS,
    consultationId,
  };
};

const sendReportFailure = (error) => ({
  type: consultationConstants.SEND_REPORT_FAILURE,
  error,
});

const clearData = () => ({
  type: consultationConstants.CLEAR_DATA,
});

export const consultationActions = {
  getNewConsultation,
  getConsultation,
  getAcceptedConsultation,
  acceptConsultation,
  rejectConsultation,
  requestScan,
  sendReport,
  getFinishedConsultations,
  getPatientActivity,
  clearData,
  requestQuestion,
  getQuestions,
};
