import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { patientReportsActions } from 'redux/actions';
import { Container, MedicalReport } from '@mous221/hk-components';
class ECG extends Component {
  componentDidMount() {
    const {
      getECG,
      match: {
        params: { id, consultationId },
      },
    } = this.props;
    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );
    if (consultationId && id && patientId) {
      getECG(id, patientId);
    }
  }
  render() {
    const {
      report: ecg,
      match: {
        params: { consultationId },
      },
    } = this.props;
    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );

    const BreadcrumbLinks =
      consultationId && patientId
        ? [
            {
              item: 'Medical Record ',
              url: `/consultation/${consultationId}`,
            },
            {
              item: 'ECGS',
              url: `/consultation/${consultationId}/ecgs/${patientId}`,
            },
          ]
        : '';
    return (
      <Container>
        {ecg && (
          <MedicalReport
            title={ecg.value}
            updatedAt={ecg.updatedAt}
            description={ecg.note}
            image={{
              fileLocation: ecg.fileLocation,
              fileName: ecg.fileName,
            }}
            BreadcrumbLinks={BreadcrumbLinks}
            consultationId={consultationId}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { report } = state.patientReports;
  return {
    report,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getECG: (id, patientId) =>
    dispatch(patientReportsActions.getECG(id, patientId)),
});

ECG.propTypes = {
  getECG: PropTypes.func.isRequired,
  report: PropTypes.object,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ECG);
