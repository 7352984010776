const schedulesConstants = {
  GET_SCHEDULES_REQUEST: 'GET_SCHEDULES_REQUEST',
  GET_SCHEDULES_SUCCESS: 'GET_SCHEDULES_SUCCESS',
  GET_SCHEDULES_FAILURE: 'GET_SCHEDULES_FAILURE',
  GET_MY_BOOKED_APPOINTMENTS_REQUEST: 'GET_MY_BOOKED_APPOINTMENTS_REQUEST',
  GET_MY_BOOKED_APPOINTMENTS_SUCCESS: 'GET_MY_BOOKED_APPOINTMENTS_SUCCESS',
  GET_MY_BOOKED_APPOINTMENTS_FAILURE: 'GET_MY_BOOKED_APPOINTMENTS_FAILURE',
  GET_MY_BOOKED_APPOINTMENTS_PER_DAY: 'GET_MY_BOOKED_APPOINTMENTS_PER_DAY',
  ADD_SCHEDULES_REQUEST: 'ADD_SCHEDULES_REQUEST',
  ADD_SCHEDULES_SUCCESS: 'ADD_SCHEDULES_SUCCESS',
  ADD_SCHEDULES_FAILURE: 'ADD_SCHEDULES_FAILURE',
  UPDATE_SCHEDULES_REQUEST: 'UPDATE_SCHEDULES_REQUEST',
  UPDATE_SCHEDULES_SUCCESS: 'UPDATE_SCHEDULES_SUCCESS',
  UPDATE_SCHEDULES_FAILURE: 'UPDATE_SCHEDULES_FAILURE',
  CLEAR_DATA: 'CLEAR_DATA',
};

export default schedulesConstants;
