import React from 'react';
import PropTypes from 'prop-types';
import { AddressInfoForm } from '@mous221/hk-components';

export class AddressInfo extends React.Component {
  handleCancel = (e) => {
    e.preventDefault();

    const {
      form: { setFields },
      user,
      handleFormToggle,
    } = this.props;
    const { address } = user;

    setFields({
      country: {
        value: address && address.country,
      },
      city: {
        value: address && address.city,
      },
      street: {
        value: address && address.street,
      },
      postelCode: {
        value: address && address.postelCode,
      },
    });
    handleFormToggle();
  };

  render() {
    const { form, user, editFormIsOpen, handleFormToggle } = this.props;

    return (
      <AddressInfoForm
        form={form}
        address={user.address}
        editFormIsOpen={editFormIsOpen}
        handleEditFormToggle={handleFormToggle}
        handleCancel={this.handleCancel}
      />
    );
  }
}

export default AddressInfo;

AddressInfo.propTypes = {
  form: PropTypes.object,
  user: PropTypes.object,
  editFormIsOpen: PropTypes.bool.isRequired,
  handleFormToggle: PropTypes.func.isRequired,
};
