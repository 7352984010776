import { combineReducers } from 'redux';
import { alert } from './alerts';
import { consultantAuth } from './consultant/auth';
import { consultation } from './consultant/consultation';
import { schedules } from './consultant/schedules';
import { patientReports } from './consultant/patientReports';

const appReducer = combineReducers({
  alert,
  consultantAuth,
  consultation,
  schedules,
  patientReports,
});

export default appReducer;
