import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  // BaseLayout,
  VerifyMobile,
  FullPage,
  Container,
  MainLayout,
} from '@mous221/hk-components';
import { consultantActions } from 'redux/actions';

class VerifyMobilePage extends Component {
  render() {
    const {
      consultant: user,
      match: {
        params: { mobileNum },
      },
    } = this.props;
    return (
      <MainLayout
        children={
          <FullPage>
            <Container>
              <VerifyMobile phone={mobileNum} {...this.props} user={user} />
            </Container>
          </FullPage>
        }
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { consultant } = state.consultantAuth;
  return {
    consultant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addMobile: (mobUser) => {
    dispatch(consultantActions.updateProfile(mobUser));
  },
  resendCode: (token) => {
    dispatch(consultantActions.resendCode(token));
  },
  verify: ({ code, token }) => {
    dispatch(consultantActions.verify({ code, token }));
  },
  logout: () => dispatch(consultantActions.logout()),
});

VerifyMobilePage.propTypes = {
  match: PropTypes.object.isRequired,
  consultant: PropTypes.object,
};

const connectedVerifyMobilePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyMobilePage);

export default connectedVerifyMobilePage;
