import React, { Component } from 'react';
import { Row, Col } from 'antd';
import styled from '@emotion/styled/macro';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { BrowserView, MobileView } from 'react-device-detect';
import { device } from 'utils';
import { consultationActions, consultantActions } from 'redux/actions';
import { Modal, PrimaryButton } from '@mous221/hk-utils';
import {
  MainLayout,
  Container,
  PatientInfo,
  Readings,
  RequestMedicalRecordForm,
  AcceptConsultants,
  CancelConsultants,
} from '@mous221/hk-components';
import {
  // Activity,
  MedicalRecord,
  PatientActivity,
  PatientConcern,
  ViewQuestions,
} from './components';

const ViewConsultationWrapper = styled.div`
  margin-top: 60px;

  .consultation-content,
  .consultation-sidebar {
    padding: 0 15px;
  }
  .general-questions .primary-button {
    min-width: 100%;
    button:disabled {
      opacity: 0.5;
    }
  }
`;

const Wrapper = styled.div`
  .modal__content {
    @media ${device.allMobile} {
      width: 80%;
    }
  }
`;
class ViewConsultation extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      getConsultation,
      consultation,
    } = this.props;

    if (id && !(consultation && consultation.id === id)) {
      getConsultation(id);
    }
  }
  state = {
    showModal: false,
    modalTitle: '',
    modalImage: '',
    scanType: '',
    showActionModal: false,
    whichModalShow: '',
    acceptedConsultantsid: null,
    cancelConsultantsid: null,
    showQuestions: false,
  };
  handelShowModal = (title, image, scanType) => {
    this.setState({
      showModal: true,
      modalTitle: title,
      modalImage: image,
      scanType,
    });
  };
  handelCloseModal = () => {
    this.setState({ showModal: false, showActionModal: false });
  };
  handelShowActionModal = (whichItem) => {
    this.setState({ showActionModal: true, whichModalShow: whichItem });
  };
  setAcceptedConsultantsid = (id) => {
    this.setState({ acceptedConsultantsid: id });
  };
  setCancelConsultantsid = (id) => {
    this.setState({ cancelConsultantsid: id });
  };
  handleCloseQuestion = () => {
    this.setState({ showQuestions: false });
  };
  render() {
    const {
      showActionModal,
      whichModalShow,
      showModal,
      modalTitle,
      modalImage,
      scanType,
      acceptedConsultantsid,
      cancelConsultantsid,
      showQuestions,
    } = this.state;
    const {
      consultation,
      requestScan,
      sendReport,
      acceptConsultation,
      rejectConsultation,
      getConsultation,
      patientActivity,
      logout,
    } = this.props;

    const blockConsultant =
      consultation &&
      ['assigned', 'pending tests'].includes(consultation.status)
        ? false
        : true;

    return (
      <Wrapper>
        <MainLayout footer="main" logout={logout}>
          <Container>
            <BrowserView>
              <ViewConsultationWrapper>
                {consultation && (
                  <Row>
                    <Col span={16} className="consultation-content">
                      {showQuestions ? (
                        <>
                          <ViewQuestions
                            handleCloseQuestion={this.handleCloseQuestion}
                            consultationId={consultation && consultation.id}
                          />
                        </>
                      ) : (
                        <>
                          <PatientConcern
                            consultation={consultation}
                            sendReport={sendReport}
                            setAcceptedConsultantsid={
                              this.setAcceptedConsultantsid
                            }
                            setCancelConsultantsid={this.setCancelConsultantsid}
                            handelShowModal={this.handelShowActionModal}
                            acceptConsultation={acceptConsultation}
                            rejectConsultation={rejectConsultation}
                            getConsultation={getConsultation}
                          />
                          <MedicalRecord
                            medicalReports={
                              consultation && consultation.medicalReports
                            }
                            allergy={consultation && consultation.allergy}
                            condition={consultation && consultation.condition}
                            ecg={consultation && consultation.ecg}
                            labResult={consultation && consultation.labResult}
                            radiology={consultation && consultation.radiology}
                            handelShowModal={this.handelShowModal}
                            patientId={consultation && consultation.patientId}
                            medicalReport={
                              consultation && consultation.medicalReport
                            }
                            consultationId={consultation && consultation.id}
                            blockConsultant={blockConsultant}
                          />
                          <div className="general-questions">
                            <PrimaryButton>
                              <button
                                disabled={consultation.status === 'new'}
                                onClick={() => {
                                  this.setState({ showQuestions: true });
                                  document.body.scrollTop = 0;
                                  document.documentElement.scrollTop = 0;
                                }}
                              >
                                General questions
                              </button>
                            </PrimaryButton>
                          </div>

                          {/* <Activity /> */}
                          {patientActivity && (
                            <PatientActivity
                              patientActivity={patientActivity.data}
                            />
                          )}
                        </>
                      )}
                    </Col>
                    <Col span={8} className="consultation-sidebar">
                      <PatientInfo patient={consultation.patient} />
                      <Readings
                        readings={consultation.readings}
                        requestScan={requestScan}
                        consultationId={consultation.id}
                        handelShowModal={this.handelShowModal}
                        blockConsultant={blockConsultant}
                      />
                    </Col>
                  </Row>
                )}
              </ViewConsultationWrapper>
            </BrowserView>
            <MobileView>
              <ViewConsultationWrapper>
                {consultation && (
                  <>
                    <PatientConcern
                      consultation={consultation}
                      sendReport={sendReport}
                      setAcceptedConsultantsid={this.setAcceptedConsultantsid}
                      setCancelConsultantsid={this.setCancelConsultantsid}
                      handelShowModal={this.handelShowActionModal}
                      acceptConsultation={acceptConsultation}
                      rejectConsultation={rejectConsultation}
                      getConsultation={getConsultation}
                    />
                    <PatientInfo patient={consultation.patient} />
                    <MedicalRecord
                      medicalReports={
                        consultation && consultation.medicalReports
                      }
                      allergy={consultation && consultation.allergy}
                      condition={consultation && consultation.condition}
                      ecg={consultation && consultation.ecg}
                      labResult={consultation && consultation.labResult}
                      radiology={consultation && consultation.radiology}
                      handelShowModal={this.handelShowModal}
                      patientId={consultation && consultation.patientId}
                      medicalReport={consultation && consultation.medicalReport}
                      consultationId={consultation && consultation.id}
                      blockConsultant={blockConsultant}
                    />
                    <Readings
                      readings={consultation.readings}
                      requestScan={requestScan}
                      consultationId={consultation.id}
                      handelShowModal={this.handelShowModal}
                      blockConsultant={blockConsultant}
                    />
                    {/* <Activity /> */}
                    {patientActivity && (
                      <PatientActivity patientActivity={patientActivity.data} />
                    )}
                  </>
                )}
              </ViewConsultationWrapper>
            </MobileView>
          </Container>
        </MainLayout>
        <Modal
          title={modalTitle}
          image={modalImage}
          showModal={showModal}
          handelCloseModal={this.handelCloseModal}
        >
          <>
            <RequestMedicalRecordForm
              requestScan={requestScan}
              scanType={scanType}
              consultationId={consultation && consultation.id}
              handelCloseModal={this.handelCloseModal}
            />
          </>
        </Modal>
        {whichModalShow && (
          <Modal
            title={modalTitle}
            image={modalImage}
            showModal={showActionModal}
            handelCloseModal={this.handelCloseModal}
          >
            <>
              {whichModalShow === 'Accept' && (
                <AcceptConsultants consultantsId={acceptedConsultantsid} />
              )}
              {whichModalShow === 'Cancel' && (
                <CancelConsultants consultantsId={cancelConsultantsid} />
              )}
            </>
          </Modal>
        )}
      </Wrapper>
    );
  }
}
const mapStateToProps = (state) => {
  const { consultation, patientActivity } = state.consultation;

  return {
    consultation,
    patientActivity,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getConsultation: (id) => dispatch(consultationActions.getConsultation(id)),
  requestScan: ({ scanType, id, title, body }) =>
    dispatch(consultationActions.requestScan({ scanType, id, title, body })),
  sendReport: ({ title, note, consultationId }) =>
    dispatch(consultationActions.sendReport({ title, note, consultationId })),
  acceptConsultation: (id) =>
    dispatch(consultationActions.acceptConsultation(id)),
  rejectConsultation: (id) =>
    dispatch(consultationActions.rejectConsultation(id)),
  logout: () => dispatch(consultantActions.logout()),
});

const WrappedViewConsultation = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewConsultation);

export default WrappedViewConsultation;

ViewConsultation.propTypes = {
  match: PropTypes.object.isRequired,
  consultation: PropTypes.object,
  getConsultation: PropTypes.func.isRequired,
  requestScan: PropTypes.func.isRequired,
  sendReport: PropTypes.func.isRequired,
  rejectConsultation: PropTypes.func.isRequired,
  acceptConsultation: PropTypes.func.isRequired,
  patientActivity: PropTypes.object,
  logout: PropTypes.func.isRequired,
};
