import React from 'react';
import PropTypes from 'prop-types';

import { AboutMe } from '@mous221/hk-components';

export class AboutMeComponent extends React.Component {
  handleCancel = (e) => {
    e.preventDefault();

    const {
      form: { setFields },
      user,
      handleFormToggle,
    } = this.props;

    setFields({
      currentEmployer: {
        value: user.currentEmployer,
      },
      patientTypeId: {
        value: user.patientTypeId,
      },
      gmcNumber: {
        value: user.gmcNumber,
      },
      yearsOfExperienceAsConsultant: {
        value: user.yearsOfExperienceAsConsultant,
      },
      consultationTypes: {
        value: user.consultationTypes[0],
      },
      jobTitle: {
        value: user.jobTitle,
      },
    });
    handleFormToggle();
  };

  render() {
    const {
      user,
      form,
      consultationTypes,
      patientTypes,
      editFormIsOpen,
      handleFormToggle,
    } = this.props;

    return (
      <div>
        <AboutMe
          user={user}
          form={form}
          editFormIsOpen={editFormIsOpen}
          handleEditFormToggle={handleFormToggle}
          handleCancel={this.handleCancel}
          consultant={false}
          consultationTypes={consultationTypes && consultationTypes}
          patientTypes={patientTypes && patientTypes}
        />
      </div>
    );
  }
}

export default AboutMeComponent;

AboutMeComponent.propTypes = {
  user: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  consultationTypes: PropTypes.array.isRequired,
  patientTypes: PropTypes.array.isRequired,
  editFormIsOpen: PropTypes.bool.isRequired,
  handleFormToggle: PropTypes.func.isRequired,
};
