import React, { Component } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { H1 } from '@mous221/hk-utils';
import { consultationActions } from 'redux/actions';
import { SearchForm, ResultTable } from './';
import { connect } from 'react-redux';
import { device } from 'utils';
const ReportsWrapper = styled.div``;
const ReportsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${device.allMobile} {
    display: block;
  }
  h1 {
    line-height: 60px;
    vertical-align: middle;
    margin-right: 20px;
  }
  form {
    display: flex;
    @media ${device.allMobile} {
      display: block;
    }
    & > div {
      margin-bottom: 10px;
      margin-right: 30px;
      input {
        width: 350px;
        height: 50px;
      }
    }
    button {
      max-height: 48px;
      @media ${device.allMobile} {
        max-height: 50px;
        height: 50px;
      }
    }
  }
`;
class Reports extends Component {
  componentDidMount() {
    const { getFinishedConsultations } = this.props;
    getFinishedConsultations();
  }
  state = {
    searchDate: null,
    name: null,
  };
  setPage = (page) => {
    const { getFinishedConsultations } = this.props;
    const { searchDate, name } = this.state;

    getFinishedConsultations(page, name, searchDate);
  };
  setSearchDate = (date) => this.setState({ searchDate: date });
  setName = (name) => this.setState({ name });
  handleSearch = (page = null, name = null, date = null) =>
    this.props.getFinishedConsultations(page, name, date);

  render() {
    const { FinishedConsultations } = this.props;
    return (
      <ReportsWrapper>
        <ReportsHeader>
          <H1>Reports</H1>
          <SearchForm
            handleSearch={this.handleSearch}
            setSearchDate={this.setSearchDate}
            setName={this.setName}
          />
        </ReportsHeader>
        {FinishedConsultations && (
          <ResultTable
            FinishedConsultations={FinishedConsultations}
            setPage={this.setPage}
          />
        )}
      </ReportsWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { FinishedConsultations } = state.consultation;

  return {
    FinishedConsultations,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getFinishedConsultations: (page = null, name = null, date = null) =>
    dispatch(consultationActions.getFinishedConsultations(page, name, date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);

Reports.propTypes = {
  FinishedConsultations: PropTypes.object,
  getFinishedConsultations: PropTypes.func.isRequired,
};
