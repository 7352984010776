import React, { Component } from 'react';
import styled from '@emotion/styled/macro';
import { ProfilePic, convertDateToFullStringDate } from '@mous221/hk-utils';
import PropTypes from 'prop-types';

const BookWrapper = styled.div`
  display: flex;
  padding: 20px 16px;
  background: #fff;
  border-bottom: solid 1px #e4e9f2;
`;
const BookImage = styled.div`
  margin-right: 12px;
  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
`;
const BookDetails = styled.div`
  margin-left: 12px;
  p {
    margin-bottom: 0;
    &.book-name {
      font-size: 16px;
      font-weight: bold;
    }
    &.book-time {
      font-size: 16px;
      font-weight: normal;
      color: #9298ac;
    }
  }
`;

export default class Book extends Component {
  render() {
    const { appointment } = this.props;
    return (
      <BookWrapper className="book-wrapper">
        <BookImage>
          <ProfilePic user={appointment.patient} />
        </BookImage>
        <BookDetails>
          <p className="book-name">{appointment.patient.full_name}</p>
          <p className="book-time">
            {convertDateToFullStringDate(appointment.appointment_time)}
          </p>
        </BookDetails>
      </BookWrapper>
    );
  }
}

Book.propTypes = {
  appointment: PropTypes.object.isRequired,
};
