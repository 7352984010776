import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { patientReportsActions } from 'redux/actions';
import { Container, MedicalReport } from '@mous221/hk-components';

class XRay extends Component {
  componentDidMount() {
    const {
      getXRay,
      match: {
        params: { id, patientId },
      },
    } = this.props;

    console.log('patientId', patientId);
    console.log('id', id);
    if (id && patientId) {
      getXRay(id, patientId);
    }
  }
  render() {
    const {
      report: xray,
      location,
      match: {
        params: { consultationId, patientId },
      },
    } = this.props;

    const radiologistPrams = JSON.parse(
      new URLSearchParams(location.search).get('radiologistPrams')
    );

    const BreadcrumbLinks =
      consultationId && patientId
        ? [
            {
              item: 'Consultation ',
              url: `/consultation/${consultationId}`,
            },
            {
              item: 'XRays',
              url: `/consultation/${consultationId}/xray/${patientId}?radiologistPrams=${JSON.stringify(
                radiologistPrams
              )}`,
            },
          ]
        : [''];
    return (
      <Container>
        {xray && (
          <MedicalReport
            title={xray.value}
            updatedAt={xray.updatedAt}
            description={xray.note}
            image={{
              fileLocation: xray.fileLocation,
              fileName: xray.fileName,
            }}
            BreadcrumbLinks={BreadcrumbLinks}
            consultationId={consultationId}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { report } = state.patientReports;
  return {
    report,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getXRay: (id, patientId) =>
    dispatch(patientReportsActions.getXRay(id, patientId)),
});

XRay.propTypes = {
  getXRay: PropTypes.func.isRequired,
  report: PropTypes.object,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(XRay);
