import React, { Component } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { Condition, Condition2X } from 'images';
import { convertDateToFullStringDate } from '@mous221/hk-utils';
import Action from '../Action';

const PatientActivityHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin: 35px 0 5px;
  .patient-activity__title {
    font-size: 20px;
    font-weight: bold;
    color: #060c2b;
  }
`;
export default class PatientActivity extends Component {
  render() {
    const { patientActivity } = this.props;
    const actions = patientActivity.slice(0, 3).map((action) => {
      return (
        <Action
          key={JSON.stringify(action)}
          title={action.data.title}
          content={action.data.body}
          typeId={action.data.id}
          time={convertDateToFullStringDate(action.updatedAt)}
          type={action.data.type}
          img={{ src: Condition, src2X: Condition2X, alt: 'Condition' }}
          className="activity-cart"
        />
      );
    });
    return (
      <div>
        <PatientActivityHeader>
          <h1 className="patient-activity__title">Patient Activity</h1>
        </PatientActivityHeader>
        {actions}
      </div>
    );
  }
}

PatientActivity.propTypes = {
  patientActivity: PropTypes.array.isRequired,
};
