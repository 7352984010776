import React, { Component } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

import ActiveConsultant from '../ActiveConsultant';
import { Message, Message2X } from 'images';
import { H1 } from '@mous221/hk-utils';

const NewConsultantsWrapper = styled.div`
  margin-bottom: 30px;
  & > h1 {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  background: #fff;
  padding: 20px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  border-radius: 6px;
  margin-bottom: 15px;
`;
const ImgWrapper = styled.div`
  display: flex;
  margin-top: 5px;
  margin-right: 20px;
  img {
    width: 49px;
    height: 49px;
  }
`;
const ContentWrapper = styled.div`
  max-width: 420px;
  h2 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #97d7d8;
    margin: 0;
  }
  p {
    margin: 0;
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 17px;
    font-weight: 400;
    color: #060c2b;
  }
`;

class NewConsultants extends Component {
  render() {
    const {
      handelShowModal,
      newConsultations,
      acceptConsultation,
      rejectConsultation,
      setAcceptedConsultantsid,
      setCancelConsultantsid,
    } = this.props;

    return (
      <NewConsultantsWrapper>
        <H1>New Consultations</H1>
        {(!newConsultations ||
          (newConsultations &&
            newConsultations.data &&
            newConsultations.data.length < 1)) && (
          <HeaderWrapper>
            <ImgWrapper>
              <picture>
                <source srcSet={`${Message2X} 2x`} />
                <img src={Message} alt="Message" />
              </picture>
            </ImgWrapper>
            <ContentWrapper>
              <h2>there’s no consultation yet</h2>
              <p>
                Once a patient request a consultation, you will get notified
              </p>
            </ContentWrapper>
          </HeaderWrapper>
        )}

        {newConsultations &&
          newConsultations.data &&
          newConsultations.data.map((consultation) => (
            <ActiveConsultant
              key={consultation.id}
              notAnswerYet={true}
              handelShowModal={handelShowModal}
              consultation={consultation}
              acceptConsultation={acceptConsultation}
              rejectConsultation={rejectConsultation}
              setAcceptedConsultantsid={setAcceptedConsultantsid}
              setCancelConsultantsid={setCancelConsultantsid}
            />
          ))}
      </NewConsultantsWrapper>
    );
  }
}

export default NewConsultants;

NewConsultants.propTypes = {
  newConsultations: PropTypes.object.isRequired,
  handelShowModal: PropTypes.func.isRequired,
  acceptConsultation: PropTypes.func.isRequired,
  rejectConsultation: PropTypes.func.isRequired,
  setCancelConsultantsid: PropTypes.func.isRequired,
  setAcceptedConsultantsid: PropTypes.func.isRequired,
};
