import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { patientReportsActions } from 'redux/actions';
import { PrimaryButton } from '@mous221/hk-utils';
import styled from '@emotion/styled/macro';
import { Container, MedicalReport } from '@mous221/hk-components';

const MedicalReportWrapper = styled.div`
  .primary-button {
    margin: 15px 10px;
  }
`;

class MedicalReportComponent extends Component {
  componentDidMount() {
    const {
      getMedicalReport,

      match: {
        params: { id, consultationId },
      },
    } = this.props;
    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );
    if (consultationId && id && patientId) {
      getMedicalReport(id, patientId);
    }
  }
  render() {
    const {
      report: medicalReport,
      match: {
        params: { consultationId },
      },
    } = this.props;

    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );

    const BreadcrumbLinks =
      consultationId && patientId
        ? [
            {
              item: 'Medical Record ',
              url: `/consultation/${consultationId}`,
            },
            {
              item: 'Medical Reports',
              url: `/consultation/${consultationId}/medical-reports/${patientId}`,
            },
          ]
        : '';
    return (
      <MedicalReportWrapper>
        <Container>
          {medicalReport && (
            <MedicalReport
              title={medicalReport.title}
              updatedAt={medicalReport.updatedAt}
              description={medicalReport.note}
              image={{
                fileLocation: medicalReport.fileLocation,
                fileName: medicalReport.fileName,
              }}
              BreadcrumbLinks={BreadcrumbLinks}
              consultationId={consultationId}
            />
          )}
          {medicalReport && medicalReport.generatedFile && (
            <div>
              <PrimaryButton>
                <a
                  href={medicalReport.generatedFile}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Medical Report
                </a>
              </PrimaryButton>
            </div>
          )}
        </Container>
      </MedicalReportWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { report } = state.patientReports;
  return {
    report,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getMedicalReport: (id, patientId) =>
    dispatch(patientReportsActions.getMedicalReport(id, patientId)),
});

MedicalReportComponent.propTypes = {
  getMedicalReport: PropTypes.func.isRequired,
  report: PropTypes.object,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicalReportComponent);
