import React from 'react';
import styled from '@emotion/styled/macro';
import { UndrawCreditCard, UndrawCreditCard2X } from 'images';

const EmptyPaymentWrapper = styled.div`
  display: flex;
  picture,
  picture img {
    width: 195px;
    height: 145px;
  }
  .no-method {
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3 {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 20px;
      font-weight: 600;
      color: #97d7d8;
      margin-bottom: 10px;
    }
    p {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 14px;
      color: #060c2b;
    }
  }
`;

export default function EmptyPayment() {
  return (
    <EmptyPaymentWrapper>
      <picture>
        <source srcSet={`${UndrawCreditCard2X} 2x`} />
        <img src={UndrawCreditCard} alt="Undraw Credit Card " />
      </picture>
      <div className="no-method">
        <h3>No Method Found</h3>
        <p>Please enter your payment method</p>
      </div>
    </EmptyPaymentWrapper>
  );
}
